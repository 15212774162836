import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../../common/firebase/functions/firebase-functions';
import {ApiWorkhubCore} from '../../apiWorkhubCore';
import {
  ApiTypesOrgOptionServicesChargeDetailPatchReq,
  ApiTypesOrgOptionServicesClaimCycleReq,
  ApiTypesOrgOptionServicesAddReq,
  ApiTypesOrgOptionServicesRes,
  ApiTypesOrgOptionServicesUpdateReq,
  ApiTypesOrgOptionServicesBulkUpdateRes,
  ApiTypesOrgOptionServicesBulkUpdateReq,
  ApiTypesOrgOptionServicesChargeDetailPostReq,
} from '@bitkey-service/v2_core-types/lib/api/organization/option-services/apiTypesOrgOptionServices';
import {catchStack} from '@/common/error/stacktrace';

const basePath = () => {
  return `organizations/option-services`;
};

export class V2ApiWorkhubCoreOptions {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.delete<Response>(FirebaseFunctions.makePath(basePath(), path), config).catch(catchStack());

  public static add = (data: ApiTypesOrgOptionServicesAddReq) =>
    V2ApiWorkhubCoreOptions.post<ApiTypesOrgOptionServicesRes, ApiTypesOrgOptionServicesAddReq>('/', data);

  public static update = (optionServiceId: string, data: ApiTypesOrgOptionServicesUpdateReq) =>
    V2ApiWorkhubCoreOptions.patch<ApiTypesOrgOptionServicesRes, ApiTypesOrgOptionServicesUpdateReq>(
      '/' + optionServiceId,
      data
    );

  public static bulkAdd = (data: ApiTypesOrgOptionServicesAddReq[]) =>
    V2ApiWorkhubCoreOptions.post<string[], ApiTypesOrgOptionServicesAddReq[]>('/bulk', data);

  public static bulkUpdate = (data: ApiTypesOrgOptionServicesBulkUpdateReq) =>
    V2ApiWorkhubCoreOptions.patch<ApiTypesOrgOptionServicesBulkUpdateRes, ApiTypesOrgOptionServicesBulkUpdateReq>(
      '/bulk',
      data
    );

  public static deleteByIds = (data: {optionServiceIds: string[]}) =>
    V2ApiWorkhubCoreOptions.post('/bulk/delete', data);

  public static deleteOption = (optionServiceId: string) => V2ApiWorkhubCoreOptions.delete('/' + optionServiceId);

  public static updateChargeDetail = (chargeDetailId: string, data: ApiTypesOrgOptionServicesChargeDetailPatchReq) =>
    V2ApiWorkhubCoreOptions.patch<ApiTypesOrgOptionServicesRes, ApiTypesOrgOptionServicesChargeDetailPatchReq>(
      `/update-charge-detail/${chargeDetailId}`,
      data
    );

  public static updateClaimCycle = (claimCycleId: string, data: ApiTypesOrgOptionServicesClaimCycleReq) =>
    V2ApiWorkhubCoreOptions.patch<ApiTypesOrgOptionServicesRes, ApiTypesOrgOptionServicesClaimCycleReq>(
      `/update-claim-cycle/${claimCycleId}`,
      data
    );

  public static addChargeDetail = (optionServiceId: string, data: ApiTypesOrgOptionServicesChargeDetailPostReq) =>
    V2ApiWorkhubCoreOptions.post<ApiTypesOrgOptionServicesRes, ApiTypesOrgOptionServicesChargeDetailPostReq>(
      `/${optionServiceId}/add-charge-detail`,
      data
    );
}
