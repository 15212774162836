import {Locale} from '@/common/redux/state-types/localeStateType';

export const thirdPlaceSectionMenuDef = {
  contractManagement: {default: {default: '契約管理', [Locale.en_US]: 'Contract Management'}},
  memberAndCustomerManagement: {
    default: {
      default: '会員・顧客管理',
      [Locale.en_US]: 'Member and Customer Management',
    },
  },
  billingManagement: {default: {default: '請求管理', [Locale.en_US]: 'Claim Management'}},
  reservationManagement: {default: {default: '予約管理', [Locale.en_US]: 'Reservation Management'}},
  plansAndOptions: {default: {default: 'プラン・オプション', [Locale.en_US]: 'Plan Options'}},
  thirdPlaceSetting: {default: {default: 'サードプレイス設定', [Locale.en_US]: 'Third Place Setting'}},
  v2BillingInfo: {
    default: {default: '請求', [Locale.en_US]: 'Claim Info'},
  },
  v2ReceivedMoneyInfo: {
    default: {default: '入金', [Locale.en_US]: 'Received Money Management'},
  },
  v2RefundManagement: {
    default: {default: '返金', [Locale.en_US]: 'Refund Management'},
  },
  thirdPlaceContractPlans: {
    default: {default: '定期利用', [Locale.en_US]: 'Contract Plans'},
  },
  optionService: {default: {default: 'オプションサービス', [Locale.en_US]: 'Option Service Management'}},
  dropInPlans: {default: {default: 'ドロップイン', [Locale.en_US]: 'Drop-in'}},
  dropInPricePlans: {default: {default: '料金プラン', [Locale.en_US]: 'Price Plans'}},
  thirdPlaceGuest: {
    default: {default: '会員', [Locale.en_US]: 'Membership Info'},
  },
  thirdPlaceCustomer: {
    default: {default: '顧客', [Locale.en_US]: 'Customers'},
  },
  thirdPlaceContractCreate: {
    default: {default: '新規契約作成', [Locale.en_US]: 'Create Contract'},
  },
  thirdPlaceContractRenew: {
    default: {default: '契約更新', [Locale.en_US]: 'Renew Contract'},
  },
  thirdPlaceContract: {
    default: {default: '定期利用', [Locale.en_US]: 'Contracts'},
  },
  thirdPlaceUpdateRules: {
    default: {default: '契約更新ルール', [Locale.en_US]: 'Contract Update Rules'},
  },
  thirdPlaceCancelRules: {
    default: {default: '解約ルール', [Locale.en_US]: 'Cancel Rules'},
  },
  thirdPlaceClaimCycles: {
    default: {default: '請求スケジュール', [Locale.en_US]: 'Billing Schedules'},
  },
  thirdPlaceInvoice: {
    default: {default: '帳票設定', [Locale.en_US]: 'Reports setting'},
  },
  thirdPlacePaymentMethod: {
    default: {
      default: '決済設定',
      [Locale.en_US]: 'Payment Setting',
    },
  },
  thirdPlacePassSiteSetting: {
    default: {
      default: 'workhubPass設定',
      [Locale.en_US]: 'WorkPass Setting',
    },
  },
};
