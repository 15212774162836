import {Locale} from '@/common/redux/state-types/localeStateType';

export const accountSectionMenuDef = {
  wMypage: {
    default: {
      default: 'マイページ',
      [Locale.en_US]: 'Mypage',
    },
  },
  announcement: {
    default: {default: 'お知らせ', [Locale.en_US]: 'Announcement'},
  },
  reservations: {
    default: {
      default: '予約する',
      [Locale.en_US]: 'Reservations',
    },
  },
  examine: {
    default: {
      default: '調べる',
      [Locale.en_US]: 'Examine',
    },
  },
  trafficConditions: {
    default: {
      default: '混雑状況',
      [Locale.en_US]: 'Traffic Conditions',
    },
  },
  wLocationSearch: {
    default: {
      default: '所在地検索',
      [Locale.en_US]: 'Location Search',
    },
  },
  mySchedule: {default: {default: 'マイスケジュール', [Locale.en_US]: 'My Schedule'}},
  reservationCalendar: {default: {default: 'エリア予約', [Locale.en_US]: 'Area Reservation'}},
  reservationCalendarAddPeopleCsv: {default: {default: '参加者のCSV登録', [Locale.en_US]: 'Add People CSV'}},
  reservationCalendar_bk: {default: {default: '予約カレンダー (変更前)', [Locale.en_US]: 'Reservation Calendar'}},
  reservationThingCalendar: {default: {default: '備品予約', [Locale.en_US]: 'Equipment Reservation'}},
  wLockerReservationCalendar: {
    default: {
      default: 'ロッカー予約',
      [Locale.en_US]: 'Locker Reservation',
    },
  },
};
