import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {UpsertMultiTenantContractState} from '@/common/redux/screens/state/upsertMultiTenantContractState';
import dayjs from 'dayjs';

const today = dayjs().startOf('d').valueOf();
const initialState: UpsertMultiTenantContractState = {
  basicInfo: {
    conclusionDate: today,
    contractTermFrom: today,
  },
  contractPlan: {},
  selectArea: [],
  businessCustomer: {
    type: 'input',
    registered: {status: true},
  },
  representative: {} as any,
  inChargeContractorDialog: {open: false},
  inChargeContractorInfo: [],
};

const upsertMultiTenantContractSlice = createSlice({
  name: 'upsertMultiTenantContractSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    initialize: (
      state,
      {payload}: PayloadAction<{loginUser: UpsertMultiTenantContractState['basicInfo']['employeeContractors']}>
    ) => {
      state.basicInfo.employeeContractors = payload.loginUser;
      state.basicInfo.conclusionDate = dayjs().valueOf();
      state.basicInfo.contractTermFrom = dayjs().valueOf();
    },
    setDraftData: (state, {payload}: PayloadAction<Partial<UpsertMultiTenantContractState>>) => {
      return {...state, ...payload};
    },
    updateContractPlan: (state, {payload}: PayloadAction<UpsertMultiTenantContractState['contractPlan']>) => {
      state.contractPlan = payload;
      state.selectArea = initialState.selectArea;
    },
    updateSelectArea: (state, {payload}: PayloadAction<UpsertMultiTenantContractState['selectArea']>) => {
      state.selectArea = payload;
    },
    deleteSelectArea: (state, {payload}: PayloadAction<{spaceId: string}>) => {
      state.selectArea = state.selectArea.filter(v => v.id !== payload.spaceId);
    },
    updateMemo: (state, {payload}: PayloadAction<string>) => {
      state.basicInfo.memo = payload;
    },
    updateContractCode: (state, {payload}: PayloadAction<string>) => {
      state.basicInfo.contractCode = payload;
    },
    updateConclusionDate: (state, {payload}: PayloadAction<EpochMillis>) => {
      state.basicInfo.conclusionDate = payload;
    },
    updateContractTermFrom: (state, {payload}: PayloadAction<EpochMillis>) => {
      state.basicInfo.contractTermFrom = payload;
    },
    updateContractTermTo: (state, {payload}: PayloadAction<EpochMillis>) => {
      state.basicInfo.contractTermTo = payload;
    },
    updateEmployeeContractors: (
      state,
      {payload}: PayloadAction<UpsertMultiTenantContractState['basicInfo']['employeeContractors']>
    ) => {
      state.basicInfo.employeeContractors = payload;
    },
    inputRepresentative: (
      state,
      {payload}: PayloadAction<Partial<UpsertMultiTenantContractState['representative']>>
    ) => {
      state.representative = {...state.representative, ...payload};
    },
    updateExistEmployeeError: (state, {payload}: PayloadAction<boolean>) => {
      state.existEmployeeError = payload;
    },
    inputBusinessCustomer: (
      state,
      {payload}: PayloadAction<Partial<Omit<UpsertMultiTenantContractState['businessCustomer'], 'type' | 'id'>>>
    ) => {
      state.businessCustomer = {...state.businessCustomer, ...payload};
    },
    selectAreaAddressInputAddress: state => {
      state.businessCustomer.address = state.selectArea[0]?.address;
      state.businessCustomer.postCode = state.selectArea[0]?.postCode;
    },
    setSelectBusinessCustomer: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessCustomer: Omit<UpsertMultiTenantContractState['businessCustomer'], 'type'>;
        representative: UpsertMultiTenantContractState['representative'];
      }>
    ) => {
      state.businessCustomer = {type: 'select', ...payload.businessCustomer};
      state.representative = payload.representative;
    },
    updateBusinessCustomerOrganization: (
      state,
      {payload}: PayloadAction<UpsertMultiTenantContractState['businessCustomer']['organization']>
    ) => {
      state.businessCustomer.organization = payload;
    },
    deleteSelectBusinessCustomer: state => {
      state.businessCustomer = initialState.businessCustomer;
      state.representative = initialState.representative;
    },
    updateChargeContractorDialog: (
      state,
      {payload}: PayloadAction<UpsertMultiTenantContractState['inChargeContractorDialog']>
    ) => {
      state.inChargeContractorDialog = payload;
    },
    upsertChargeContractor: (
      state,
      {
        payload,
      }: PayloadAction<
        ({type: 'add'} | {type: 'update'; preEmail: string}) & {
          data: UpsertMultiTenantContractState['inChargeContractorInfo'][number];
        }
      >
    ) => {
      if (payload.type === 'add') {
        state.inChargeContractorInfo = [payload.data, ...state.inChargeContractorInfo];
      } else {
        state.inChargeContractorInfo = state.inChargeContractorInfo.map(v =>
          v.email === payload.preEmail ? payload.data : v
        );
      }
    },
    deleteChargeContractor: (state, {payload}: PayloadAction<{email: string}>) => {
      state.inChargeContractorInfo = state.inChargeContractorInfo?.filter(v => v.email !== payload.email);
    },
    createContract: (state, {payload}: PayloadAction<{id: string; code: string}>) => {
      state.id = payload.id;
      state.basicInfo.contractCode = payload.code;
    },
  },
});
export default upsertMultiTenantContractSlice;
