import {generateSHA256} from '@/common/crypto/crypto';

export enum LocalStorageKey {
  // TODO タブごとに別で持てるようにする
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  // 次回ログインするpersonaを覚えておくため
  PersonaId = 'PersonaId',
  OrganizationId = 'OrganizationId',
  // personaとemailの一致を確認しないとログイン詰むため必須
  Email = 'Email',
  // LayoutPath = 'LayoutPath', // TODO テスト用。削除
  FloorId = 'FloorId',
  ParentAreaId = 'ParentAreaId',
  CalendarToggle = 'CalendarToggle',
  ReservationFloorMapDisplaySpaceListToggle = 'ReservationFloorMapDisplaySpaceListToggle',
  LocationSearchPeopleIconScaleFactor = 'LocationSearchPeopleIconScaleFactor', // 所在地検索のiconサイズをキャッシュするのに使用
  LocationSearchBuildingId = 'LocationSearchBuildingId', // 所在地検索でユーザーが選択したビルのSpaceId
  CalendarSortOrder = 'CalendarSortOrder',
  PaginationPageSize = 'PaginationPageSize', //ページネーションのページサイズ
  SelectedLanguage = 'SelectedLanguage', // ユーザーが選択した言語
  ReservationParticipantSearchFacet = 'ReservationParticipantSearchFacet', //予約参加者検索のfacetで選択した条件。
  ReservationCalendarSelectedTabId = 'ReservationCalendarSelectedTabId', //予約カレンダー、フロアマップで選択されたタブ
  SelectedLanguageForNoAuth = 'SelectedLanguageForNoAuth', // 認証を必要としないworkhubの画面でユーザーが選択した言語
  NotificationUserSearchFacet = 'NotificationUserSearchFacet', //通知設定ユーザー選択facetで選択した条件。
  ArrivalNotificationUserSearchFacet = 'ArrivalNotificationUserSearchFacet', //ゲスト到着設定ユーザー選択facetで選択した条件。
  // デバイス一覧ページ
  ThingColorMapping = 'ThingColorMapping', // api /subscriptions からデバイスの色情報を取得したときの値
  ThingColorMappingUpdatedAt = 'ThingColorMappingUpdatedAt', // ThingColorMapping を更新したときの日時の値
  DisableBulkReservationSettingsExplanation = 'DisableBulkReservationSettingsExplanation', // エリア一覧/一括更新設定の確認ダイアログの表示状態（次回から表示しない）
  DevToolsVisibility = 'DevTools', // デバッグ用のツールの表示状態
}

export default class LocalStorage {
  public static get = (key: LocalStorageKey) => {
    // nullは使いづらい・・・
    const res = localStorage.getItem(key);
    return res === null ? undefined : res;
  };
  public static set = (key: LocalStorageKey, value: string) => localStorage.setItem(key, value);
  public static remove = (key: LocalStorageKey) => localStorage.removeItem(key);
}

export class LoginPersonaLocalStorage {
  /**
   * 組織ペルソナのログイン情報をLocalStorageに保存する
   * emailはハッシュ化して保存する
   */
  public static setOnLogin = async ({
    accessToken,
    refreshToken,
    organizationId,
    personaId,
    email,
  }: {
    accessToken: string;
    refreshToken: string;
    organizationId: string;
    personaId: string;
    email: string;
  }) => {
    const localStorageEmail = email ? await this.getHash(email) : '';
    LocalStorage.set(LocalStorageKey.AccessToken, accessToken);
    LocalStorage.set(LocalStorageKey.RefreshToken, refreshToken);
    LocalStorage.set(LocalStorageKey.OrganizationId, organizationId);
    LocalStorage.set(LocalStorageKey.PersonaId, personaId);
    LocalStorage.set(LocalStorageKey.Email, localStorageEmail);
  };

  /**
   * リフレッシュトークンの更新時にLocalStorageに保存する
   */
  public static setOnRefresh = ({accessToken, refreshToken}: {accessToken: string; refreshToken: string}) => {
    LocalStorage.set(LocalStorageKey.AccessToken, accessToken);
    LocalStorage.set(LocalStorageKey.RefreshToken, refreshToken);
  };

  /**
   * リフレッシュトークンの更新時にLocalStorageに保存する
   */
  public static setOnlyAccessToken = ({accessToken}: {accessToken: string}) => {
    LocalStorage.set(LocalStorageKey.AccessToken, accessToken);
  };

  /**
   * token のみを削除する
   * 前回のログインしたペルソナ等を保持したい場合に使用する
   */
  public static clearToken = () => {
    LocalStorage.remove(LocalStorageKey.AccessToken);
    LocalStorage.remove(LocalStorageKey.RefreshToken);
  };

  /**
   * 前回のログインに関する情報をすべて削除する
   * 前回のログイン状態を保持しておきたくない場合に使用する
   * 例えば、ログインに必ず失敗する最終ログイン組織Persona情報残しちゃうと
   * ログイン失敗し続けて管理画面にログインできず、別の所属組織へのスイッチ動線が閉じてしまう場合など。
   */
  public static clear = () => {
    LocalStorage.remove(LocalStorageKey.AccessToken);
    LocalStorage.remove(LocalStorageKey.RefreshToken);
    LocalStorage.remove(LocalStorageKey.OrganizationId);
    LocalStorage.remove(LocalStorageKey.PersonaId);
    LocalStorage.remove(LocalStorageKey.Email);
  };

  public static get = () => {
    // ハッシュ化されているEmailは返さない。
    return {
      accessToken: LocalStorage.get(LocalStorageKey.AccessToken),
      refreshToken: LocalStorage.get(LocalStorageKey.RefreshToken),
      organizationId: LocalStorage.get(LocalStorageKey.OrganizationId),
      personaId: LocalStorage.get(LocalStorageKey.PersonaId),
    };
  };

  /**
   * 引数のEmailが最終ログインのEmailと一致するかどうかを検証する
   * LocalStorageのemailがハッシュ化ロジックを隠したいので、メソッドとして作成している。
   */
  public static isEmailSameAsLastLoginEmail = async (email: string) => {
    const hash = await this.getHash(email);
    return hash === LocalStorage.get(LocalStorageKey.Email);
  };

  private static getHash = async (str: string) => {
    const hash = await generateSHA256(str);
    const base64String = Buffer.from(hash, 'hex').toString('base64');
    return base64String;
  };
}
