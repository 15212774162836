import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../../common/firebase/functions/firebase-functions';
import {ApiCoreOrg} from '../apiCoreOrg';
import {
  ApiTypesOrgDropInSpaceSettingsGetRes,
  ApiTypesOrgDropInSpaceSettingsAddReq,
  ApiTypesOrgDropInSpaceSettingsCreateRes,
  ApiTypesOrgDropInSpaceSettingsUpdateReq,
  ApiTypesOrgDropInSpaceSettingsUpdateRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/drop-in-space-settings/apiTypesOrgDropInSpaceSettings';

const basePath = 'drop-in-space-settings';
export class ApiWorkhubCoreDropInSpaceSettings {
  private static get = <Response>(path: string) => ApiCoreOrg.get<Response>(FirebaseFunctions.makePath(basePath, path));
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.delete<Response>(FirebaseFunctions.makePath(basePath, path), config);

  public static getById = (dropInSpaceSettingId: string) =>
    ApiWorkhubCoreDropInSpaceSettings.get<ApiTypesOrgDropInSpaceSettingsGetRes>(`/${dropInSpaceSettingId}`);

  public static add = (data: ApiTypesOrgDropInSpaceSettingsAddReq) =>
    ApiWorkhubCoreDropInSpaceSettings.post<
      ApiTypesOrgDropInSpaceSettingsCreateRes,
      ApiTypesOrgDropInSpaceSettingsAddReq
    >('/', data);

  public static update = (dropInSpaceSettingId: string, data: ApiTypesOrgDropInSpaceSettingsUpdateReq) =>
    ApiWorkhubCoreDropInSpaceSettings.patch<
      ApiTypesOrgDropInSpaceSettingsUpdateRes,
      ApiTypesOrgDropInSpaceSettingsUpdateReq
    >(`/${dropInSpaceSettingId}`, data);
}
