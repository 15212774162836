import ArrayUtil from '@/common/utils/arrayUtil';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import {createSlice} from '@reduxjs/toolkit';
import {AlgoliaTableCompleteType} from '../state-types/algoliaTableCompleteTypeState';

const initialCommonState = {
  complete: false,
};

const initialState: AlgoliaTableCompleteType = {
  people: initialCommonState,
};

const algoliaTableCompleteTypeSlice = createSlice({
  name: 'algoliaTableCompleteType',
  initialState,
  reducers: {
    setCreatedPeople: (state, action: {payload: V2StoreTypesOrgPeople[]}) => ({
      ...state,
      people: {
        ...state.people,
        actions: state.people?.actions
          ? ArrayUtil.removeDuplicateBySet([...state.people.actions, 'create'])
          : ['create'],
        createData: state.people?.createData ? [...state.people.createData, ...action.payload] : action.payload,
        complete: true,
      },
    }),
    setUpdatedPeople: (state, action: {payload: V2StoreTypesOrgPeople[]}) => ({
      ...state,
      people: {
        ...state.people,
        actions: state.people?.actions
          ? ArrayUtil.removeDuplicateBySet([...state.people.actions, 'update'])
          : ['update'],
        // 作成後、更新された people は、一覧に出てきてしまうのを防ぐため、createDataから除外
        createData: state.people?.createData?.filter(d => !action.payload.some(p => p.id === d.id)),
        // 既に同じ people が更新されていた場合、上書きする
        updateData: state.people?.updateData
          ? [...state.people.updateData.filter(d => !action.payload.some(p => p.id === d.id)), ...action.payload]
          : action.payload,
        complete: true,
      },
    }),
    setDeletedPeopleIds: (state, action: {payload: string[]}) => ({
      ...state,
      people: {
        ...state.people,
        actions: state.people?.actions
          ? ArrayUtil.removeDuplicateBySet([...state.people.actions, 'delete'])
          : ['delete'],
        // 作成 or 更新後に削除された people は、一覧に出てきてしまうのを防ぐため、createData、updateDataから除外
        createData: state.people?.createData?.filter(d => !action.payload.includes(d.id)),
        updateData: state.people?.updateData?.filter(d => !action.payload.includes(d.id)),
        deleteIds: state.people?.deleteIds ? [...state.people.deleteIds, ...action.payload] : action.payload,
        complete: true,
      },
    }),
    clear: () => initialState,
  },
});

export default algoliaTableCompleteTypeSlice;
