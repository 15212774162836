import {SpecifiedCommercialTransactionSettingState} from '@/common/redux/screens/state/specifiedCommercialTransactionSettingState';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE: SpecifiedCommercialTransactionSettingState = {id: '', organizationId: ''};

const specifiedCommercialTransactionSettingSlice = createSlice({
  name: 'specifiedCommercialTransactionSetting',
  initialState: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    set: (_, {payload}: PayloadAction<SpecifiedCommercialTransactionSettingState>) => {
      return {
        ...payload,
      };
    },
  },
});

export default specifiedCommercialTransactionSettingSlice;
