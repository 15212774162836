import {lazy, useEffect} from 'react';
import {useLocation} from 'react-router';

const InitialSwitcher = lazy(() => import('./InitialSwitcher'));
const SSOLogin = lazy(() => import('./SSOLogin'));
const SSOAuth = lazy(() => import('./SSOAuth'));
const PersonalIdentificationScreen = lazy(() => import('@/mobile/screens/application/PersonalIdentificationScreen'));
const WSsoSignupScreen = lazy(() => import('@/wscreens/signup/WSsoSignupScreen'));

export const Routing = () => {
  const {pathname} = useLocation();
  const isLogin = new RegExp(/^(?!.*auth)(?!.*signup).*(?=sso.).*$/);

  useEffect(() => {
    if (!window.gtag) return;
    if (!import.meta.env.VITE_GA_ID) {
      return;
    }
    window.gtag('config', import.meta.env.VITE_GA_ID, {page_path: location.pathname});
  }, [pathname]);

  if (isLogin.test(pathname)) {
    return <SSOLogin />;
  } else if (pathname === '/sso/auth') {
    return <SSOAuth />;
  } else if (pathname === '/application-draft') {
    return <PersonalIdentificationScreen />;
  } else if (pathname === '/sso/signup') {
    return <WSsoSignupScreen />;
  } else {
    return <InitialSwitcher />;
  }
};
