import {FeatureAuthority} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';
import {ApiAccountAuthorityPattern} from '@/api/account/authority-pattern/apiAccountAuthorityPattern';
import {Feature} from '@/common/feature-control/featureDefinitions';
import {Locale} from '@/common/redux/state-types/localeStateType';

// 量多いから記述量減らすのと管理しやすくするためこの形で持っておいてdictの形にあとで加工する
export type AuthorityDictDef = {
  [K in Feature]: {
    name: {default: string} & {[L in Locale]?: string};
    description: {default: string} & {[L in Locale]?: string};
  };
};

export const authorityDictDef: AuthorityDictDef = {
  [Feature.Mypage]: {
    name: {
      default: '「マイページ」メニュー',
      [Locale.en_US]: 'My page',
    },
    description: {
      default: '予約やエリア情報など、ユーザーごとにカスタマイズされた様々な情報を閲覧できます。',
      [Locale.en_US]:
        'View a variety of information customized for each user, including reservations and area information',
    },
  },
  [Feature.Dashboard]: {
    name: {
      default: 'ダッシュボード',
      [Locale.en_US]: 'Dashboard',
    },
    description: {
      default: 'ダッシュボードにアクセスできます。',
      [Locale.en_US]: 'Can access dashboard.',
    },
  },
  [Feature.EventReservation]: {
    name: {
      default: 'イベント予約',
      [Locale.en_US]: 'Event reservation',
    },
    description: {
      default: 'イベント予約機能を使用できます。',
      [Locale.en_US]: 'Can access event reservation feature.',
    },
  },
  [Feature.CompanyInformation]: {
    name: {
      default: '会社情報',
      [Locale.en_US]: 'Company information',
    },
    description: {
      default: '会社情報にアクセスできます。',
      [Locale.en_US]: 'Can access company information.',
    },
  },
  [Feature.Subscriptions]: {
    name: {
      default: 'サブスクリプション',
      [Locale.en_US]: 'Subscription',
    },
    description: {
      default: 'サブスクリプション情報にアクセスできます。',
      [Locale.en_US]: 'Can access subscription information.',
    },
  },
  [Feature.Authority]: {
    name: {
      default: '「権限設定」メニュー',
      [Locale.en_US]: 'Authority setting',
    },
    description: {
      default: 'workhubのメニューや機能の権限を管理できます。',
      [Locale.en_US]: 'Manage menu and authority',
    },
  },
  [Feature.Bitlock]: {
    name: {
      default: 'bitlock',
      [Locale.en_US]: 'bitlock',
    },
    description: {
      default: '登録されているbitlockの情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'bitlock',
    },
  },
  [Feature.Bitlink]: {
    name: {
      default: 'bitlink',
      [Locale.en_US]: 'bitlink',
    },
    description: {
      default: '登録されているbitlinkの情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'bitlink',
    },
  },
  [Feature.ReservationCalendar]: {
    name: {
      default: '「エリア予約」メニュー',
      [Locale.en_US]: 'Calendar',
    },
    description: {
      default: '会議室や施設、ワークスポットなどの予約ができます。',
      [Locale.en_US]: 'Calendar',
    },
  },
  [Feature.ReservationCalendarAddPeopleCsv]: {
    name: {
      default: '参加者のCSV登録',
      [Locale.en_US]: 'Calendar Add People CSV',
    },
    description: {
      default: '予約の参加者をCSVで登録できます。',
      [Locale.en_US]: 'Calendar',
    },
  },
  [Feature.ReservationCalendarMySchedule]: {
    name: {
      default: '「マイスケジュール」メニュー',
      [Locale.en_US]: 'MySchedule',
    },
    description: {
      default: '個人の予約情報を閲覧できます。',
      [Locale.en_US]: 'View personal reservation information.',
    },
  },
  [Feature.ReservationThingCalendar]: {
    name: {
      default: '「備品予約」メニュー',
      [Locale.en_US]: 'Thing Calendar',
    },
    description: {
      default: '備品の予約ができます。',
      [Locale.en_US]: 'Equipment can be reserved.',
    },
  },
  [Feature.CoworkingReservationCalendar]: {
    name: {
      default: 'コワーキングカレンダー',
      [Locale.en_US]: 'Coworking Calendar',
    },
    description: {
      default: 'コワーキング向けのカレンダー機能を利用できるようになります。',
      [Locale.en_US]: 'Coworking Calendar',
    },
  },
  [Feature.V2LockerReservationCalendar]: {
    name: {
      default: '「ロッカー予約」メニュー',
      [Locale.en_US]: 'Locker Calendar',
    },
    description: {
      default: 'ロッカーの予約ができます。',
      [Locale.en_US]: 'You can use the calendar function for lockers.',
    },
  },
  [Feature.CongestionStatus]: {
    name: {
      default: '混雑の基準値設定',
      [Locale.en_US]: 'Congestion status',
    },
    description: {
      default: '混雑の基準値を設定できます。',
      [Locale.en_US]: 'Congestion status',
    },
  },
  [Feature.BitlockGate]: {
    name: {
      default: 'bitlock GATE',
      [Locale.en_US]: 'bitlock GATE',
    },
    description: {
      default: 'bitlock GATEの情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'bitlock GATE',
    },
  },
  [Feature.Bitreader]: {
    name: {
      default: 'bitreader',
      [Locale.en_US]: 'bitreader',
    },
    description: {
      default: 'bitreaderの情報・状態・設定を参照・変更したり、配信や削除等のオペレーションが可能になります。',
      [Locale.en_US]: 'bitreader',
    },
  },
  [Feature.Building]: {
    name: {
      default: '建物管理',
      [Locale.en_US]: 'Building management',
    },
    description: {
      default: '建物の情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'Building management',
    },
  },
  [Feature.Room]: {
    name: {
      default: 'ルーム管理',
      [Locale.en_US]: 'Room management',
    },
    description: {
      default: '部屋の情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'Room management',
    },
  },
  [Feature.Floor]: {
    name: {
      default: 'フロア管理',
      [Locale.en_US]: 'Floor management',
    },
    description: {
      default: 'フロアの情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'Floor management',
    },
  },
  [Feature.Announcement]: {
    name: {
      default: '「お知らせ」メニュー',
      [Locale.en_US]: 'Announcement',
    },
    description: {
      // TODO これは常に開放してていいはず。ログイン可否の制御にも使わない。
      default: 'Bitkeyや管理者からのお知らせを閲覧できます。',
      [Locale.en_US]: 'Announcement',
    },
  },
  [Feature.WifiConnector]: {
    name: {
      default: 'Wi-Fi コネクタ',
      [Locale.en_US]: 'Wi-Fi connector',
    },
    description: {
      default: 'Wi-Fi コネクタの情報・状態・設定を参照・変更できます。',
      [Locale.en_US]: 'Wi-Fi connector',
    },
  },
  [Feature.Attendance]: {
    name: {
      default: '「勤怠設定」メニュー',
      [Locale.en_US]: 'Attendance management',
    },
    description: {
      // TODO こういうやつは権限わけないとだめ。設定は変更できるけど勤怠データ見ちゃだめな人もいるはず。
      default: '出退勤情報に関するデータや設定を参照・変更できます。',
      [Locale.en_US]: 'Attendance management',
    },
  },
  [Feature.EntryExit]: {
    name: {
      default: '入退室管理',
      [Locale.en_US]: 'Entry/exit management',
    },
    description: {
      default: '入退室情報に関するデータや設定を参照・変更できます。',
      [Locale.en_US]: 'Entry/exit management',
    },
  },
  [Feature.Face]: {
    name: {
      default: '顔情報',
      [Locale.en_US]: 'Face information',
    },
    description: {
      default: '顔情報に関連するデータや設定を参照・変更できます。',
      [Locale.en_US]: 'Face information',
    },
  },
  [Feature.FaceTablet]: {
    name: {
      default: '顔認証タブレット',
      [Locale.en_US]: 'Face recognition tablet',
    },
    description: {
      default: '顔認証タブレットの設定を確認したり、変更したりできます。',
      [Locale.en_US]: 'Face recognition tablet',
    },
  },
  [Feature.Nfc]: {
    name: {
      default: 'NFCカード',
      [Locale.en_US]: 'NFC cards',
    },
    description: {
      default: 'NFCカードの情報を参照・変更できます。',
      [Locale.en_US]: 'NFC cards',
    },
  },
  [Feature.Reception]: {
    name: {
      default: '受付',
      [Locale.en_US]: 'Reception',
    },
    description: {
      default: '受付情報にアクセスできます。',
      [Locale.en_US]: 'Reception',
    },
  },
  [Feature.V2Reception]: {
    name: {
      default: '受付',
      [Locale.en_US]: 'Reception',
    },
    description: {
      default: '受付情報にアクセスできます。',
      [Locale.en_US]: 'Reception',
    },
  },
  [Feature.ReceptionSetting]: {
    name: {
      default: '「受付設定」メニュー',
      [Locale.en_US]: 'Reception settings',
    },
    description: {
      default: '受付時の設定や通知、受付時確認項目を登録・管理できます。',
      [Locale.en_US]: 'Reception settings',
    },
  },
  [Feature.ReceptionSettingTemplate]: {
    name: {
      default: '「受付設定テンプレート」メニュー',
      [Locale.en_US]: 'Reception settings Template',
    },
    description: {
      default: '受付デバイスの設定テンプレートを登録・管理できます。',
      [Locale.en_US]: 'Reception settings Template',
    },
  },
  [Feature.CloudCamera]: {
    name: {
      default: 'クラウドカメラ設定',
      [Locale.en_US]: 'Cloud Camera Settings',
    },
    description: {
      default: 'クラウドカメラの設置および設定ができます。',
      [Locale.en_US]: 'You can install and configure cloud cameras.',
    },
  },
  [Feature.V2ReceptionData]: {
    name: {
      default: '「受付データ」メニュー',
      [Locale.en_US]: 'Reception Data',
    },
    description: {
      default: '受付ごとのデータを閲覧できます。',
      [Locale.en_US]: 'Reception Data',
    },
  },
  [Feature.V2ReceptionGuest]: {
    name: {
      default: '「受付ゲスト」メニュー',
      [Locale.en_US]: 'Reception Guest',
    },
    description: {
      default: 'ゲストごとの受付データを閲覧できます。',
      [Locale.en_US]: 'Reception Guest',
    },
  },
  [Feature.LinkageSetting]: {
    name: {
      default: '「外部サービス連携」メニュー',
      [Locale.en_US]: 'Linkage settings',
    },
    description: {
      default: 'ゲスト到着通知を外部サービスと連携できます。',
      [Locale.en_US]: 'Linkage settings',
    },
  },
  [Feature.SSOSetting]: {
    name: {
      default: '「SSO連携設定」メニュー',
      [Locale.en_US]: 'SSO settings',
    },
    description: {
      default: 'SSOの連携にまつわる設定を行えます。',
      [Locale.en_US]: 'SSO settings',
    },
  },
  [Feature.ReservationSetting]: {
    name: {
      default: '「予約設定」メニュー',
      [Locale.en_US]: 'Reservation settings',
    },
    description: {
      default: '予約に関する設定ができます。',
      [Locale.en_US]: 'Reservation settings',
    },
  },
  [Feature.SecurityCamera]: {
    name: {
      default: 'セキュリティカメラ',
      [Locale.en_US]: 'Security camera',
    },
    description: {
      default: 'セキュリティカメラに関する情報・状態・設定の確認・変更ができます。',
      [Locale.en_US]: 'Security camera',
    },
  },
  [Feature.UnlockConditionTemplate]: {
    name: {
      default: '解錠条件テンプレート',
      [Locale.en_US]: 'Unlock condition template',
    },
    description: {
      default: '解錠条件テンプレートの確認・変更ができます。',
      [Locale.en_US]: 'Unlock condition template',
    },
  },
  [Feature.Users]: {
    name: {
      default: 'ユーザー管理',
      [Locale.en_US]: 'Users',
    },
    description: {
      default: 'workhubユーザーに関する情報の確認・設定の変更や、招待を始めとする各種オペレーションが可能になります。',
      [Locale.en_US]: 'Users',
    },
  },
  [Feature.V2MailTemplates]: {
    name: {
      default: '「メールテンプレート設定」メニュー',
      [Locale.en_US]: 'Mail templates',
    },
    description: {
      default: '予約時に送信されるメールのテンプレートを作成・管理できます。',
      [Locale.en_US]: 'Mail templates',
    },
  },
  [Feature.V2Notification]: {
    name: {
      default: '通知設定',
      [Locale.en_US]: 'Notification settings',
    },
    description: {
      default: '通知を行うメールアドレスの設定が可能になります。',
      [Locale.en_US]: 'You can set the e-mail address to notify',
    },
  },
  [Feature.V2OrganizationSetting]: {
    name: {
      default: '「組織設定」メニュー',
      [Locale.en_US]: 'Organization settings',
    },
    description: {
      default: '組織の情報を管理できます。',
      [Locale.en_US]: 'It allows you to configure settings related to the organization.',
    },
  },
  [Feature.FMDashboard]: {
    name: {
      default: '「FMダッシュボード設定」メニュー',
      [Locale.en_US]: 'FM Dashboard',
    },
    description: {
      default: 'FMダッシュボードで表示する情報を管理できます。',
      [Locale.en_US]: 'You can manage the information displayed on the FM dashboard.',
    },
  },
  v2AccessRights: {
    name: {
      default: '「アクセスコントロール」メニュー',
      [Locale.en_US]: 'Access rights',
    },
    description: {
      default:
        'アクセスコントロールを登録・管理できます。\n' +
        '※アクセスコントロール詳細画面でのカギ束の編集は、システム管理者のみ操作可能です。',
      [Locale.en_US]: 'Access rights',
    },
  },
  [Feature.PasscodeGroups]: {
    name: {
      default: '「固定パスコード」メニュー',
      [Locale.en_US]: 'Passcode',
    },
    description: {
      default: '固定パスコードを登録・管理できます。',
      [Locale.en_US]: 'You can register and manage passcodes.',
    },
  },
  v2Areas: {
    name: {
      default: '「エリア」メニュー',
    },
    description: {
      default: 'エリアの情報を登録・管理できます。',
    },
  },
  v2Buildings: {
    name: {
      default: '「ビル」メニュー',
    },
    description: {
      default: 'ビルの情報を登録・管理できます。',
    },
  },
  v2CastGroups: {
    name: {
      default: '「業者グループ」メニュー',
    },
    description: {
      default: '業者のグループを作成・管理できます。',
    },
  },
  v2Casts: {
    name: {
      default: '「業者」メニュー',
    },
    description: {
      default: '業者の情報を登録・管理できます。',
    },
  },
  v2Customers: {
    name: {
      default: '「取引先」メニュー',
    },
    description: {
      default: '取引先の情報を登録・管理できます。',
    },
  },
  v2EmployeeGroupSettings: {
    name: {
      default: '「従業員グループ」メニュー',
    },
    description: {
      default: '従業員同士のグループを作成・管理できます。',
    },
  },
  v2Members: {
    name: {
      default: '「従業員」メニュー',
    },
    description: {
      default: '従業員の情報を登録・管理できます。',
    },
  },
  v2Equipments: {
    name: {
      default: '「備品」メニュー',
    },
    description: {
      default: '備品の情報を登録・管理できます。',
    },
  },
  V2Lockers: {
    name: {
      default: '「ロッカー」メニュー',
      [Locale.en_US]: 'Locker',
    },
    description: {
      default: 'ロッカーの情報を登録・管理できます',
      [Locale.en_US]: 'You will be able to manage lockers. You can also set up lockers with workhubApp.',
    },
  },
  [Feature.Shelves]: {
    name: {
      default: '「収納棚」メニュー',
      [Locale.en_US]: 'Shelf',
    },
    description: {
      default: '棚の情報を登録・管理できます',
      [Locale.en_US]: 'You will be able to manage shelves. You can also set up shelves with workhubApp.',
    },
  },
  v2EmploymentStatuses: {
    name: {
      default: '「雇用形態」メニュー',
    },
    description: {
      default: '雇用形態の情報を登録・管理できます。',
    },
  },
  v2Floors: {
    name: {
      default: '「フロア」メニュー',
    },
    description: {
      default: 'フロアの情報を登録・管理できます。',
    },
  },
  v2KeyBundles: {
    name: {
      default: '「カギ束」メニュー',
    },
    description: {
      default: 'カギ束を登録・管理できます。',
    },
  },
  v2Layouts: {
    name: {
      default: '「レイアウト」メニュー',
    },
    description: {
      default: 'レイアウトの情報を登録・管理できます。',
    },
  },
  v2OfficeDepartments: {
    name: {
      default: '「事業所・部門」メニュー',
    },
    description: {
      default: '事業所・部門の情報を登録・管理できます。',
    },
  },
  v2Positions: {
    name: {
      default: '「役職」メニュー',
    },
    description: {
      default: '役職の情報を登録・管理できます。',
    },
  },
  v2EmployeesAttendance: {
    name: {
      default: '「出社状況」メニュー',
    },
    description: {
      default: '従業員の出社状況データが閲覧できます。',
    },
  },
  v2LocationSearch: {
    name: {
      default: '「所在地検索」メニュー',
    },
    description: {
      default: 'ユーザーの所在地を閲覧できます。',
    },
  },
  v2Regions: {
    name: {
      default: '「拠点」メニュー',
    },
    description: {
      default: '拠点の情報を登録・管理できます。',
    },
  },
  v2SecurityCards: {
    name: {
      default: '「セキュリティカード」メニュー',
    },
    description: {
      default: 'セキュリティカードを登録・管理できます。',
    },
  },
  [Feature.SignageEmployeeAttendance]: {
    name: {
      default: '「出社状況」メニュー',
      [Locale.en_US]: 'EmployeeAttendance',
    },
    description: {
      default: '出社状況のサイネージ用データを閲覧できます。',
      [Locale.en_US]: 'You can display employee attendance in signage.',
    },
  },
  [Feature.SignageCheckInInformation]: {
    name: {
      default: '「チェックイン情報」メニュー',
      [Locale.en_US]: 'Check-in information',
    },
    description: {
      default: 'チェックイン情報のサイネージ用データを閲覧できます。',
      [Locale.en_US]: 'You can display check-in information in signage.',
    },
  },
  [Feature.SignageTrafficCondition]: {
    name: {
      default: '「混雑状況」メニュー',
      [Locale.en_US]: 'TrafficConditions',
    },
    description: {
      default: '混雑状況のサイネージ用データを閲覧できます。',
      [Locale.en_US]: 'You can display traffic conditions in signage.',
    },
  },
  [Feature.TrafficConditions]: {
    name: {
      default: '「混雑状況」メニュー',
      [Locale.en_US]: 'TrafficConditions',
    },
    description: {
      default: 'ピープルカウントが設置されているエリアの混雑状況を閲覧できます。',
      [Locale.en_US]: 'You can display traffic conditions.',
    },
  },
  v2SecurityDevices: {
    name: {
      default: '「デバイス」メニュー',
    },
    description: {
      default: 'セキュリティデバイスの登録・管理ができます。',
    },
  },
  [Feature.V2SecurityDeviceAlert]: {
    name: {
      default: '「デバイスアラート」メニュー',
      [Locale.en_US]: 'Device Alert',
    },
    description: {
      default: '電池交換やオフラインなど、デバイスのアラートを確認できます。',
      [Locale.en_US]: 'Check device alerts for battery replacement, offline, etc.',
    },
  },
  v2TemporaryCardGroups: {
    name: {
      default: '「一時利用カードグループ」メニュー',
    },
    description: {
      default: '一時利用カードのグループを登録・管理できます。',
    },
  },
  [Feature.V2SecurityCardGroups]: {
    name: {
      default: '「セキュリティカードグループ」メニュー',
    },
    description: {
      default: 'セキュリティカードグループのグループを登録・管理できます。',
    },
  },
  v2Visitors: {
    name: {
      default: '「来訪者」メニュー',
    },
    description: {
      default: '来訪者の情報を登録・管理できます。',
    },
  },
  [Feature.DeleteFaceInformationForGuest]: {
    name: {
      default: '来訪者の顔情報削除',
      [Locale.en_US]: 'Delete face information for guest',
    },
    description: {
      default: '来訪者の顔情報を削除できます。',
      [Locale.en_US]: 'Delete face information for guest',
    },
  },
  v2Workspots: {
    name: {
      default: '「ワークスポット」メニュー',
    },
    description: {
      default: 'ワークスポットの情報を登録・管理できます。',
    },
  },
  [Feature.V2Manual]: {
    name: {
      default: '「マニュアル」メニュー・サポートサイト',
    },
    description: {
      default: 'マニュアルおよびサポートサイトを閲覧できます。',
    },
  },
  [Feature.V2ReadTemperatureLogs]: {
    name: {
      default: '',
      [Locale.en_US]: '',
    },
    description: {
      default: '',
      [Locale.en_US]: '',
    },
  },
  [Feature.V2ServiceApplication]: {
    name: {
      default: 'サービス利用申請',
      [Locale.en_US]: 'Application for Service Use',
    },
    description: {
      default: '事業者に対する各種申請が可能になります。',
      [Locale.en_US]: 'Various applications for businesses will be available',
    },
  },
  [Feature.BmMigrateAssistant]: {
    name: {
      default: '「bM移行アシスタント」メニュー',
      [Locale.en_US]: 'bM migrate assistant',
    },
    description: {
      default: 'bitlock Manager(bM)からworkhubへの移行が可能になります。',
      [Locale.en_US]: 'You can migrate from bitlock Manager (bM) to workhub.',
    },
  },
  [Feature.BmMigrateTour]: {
    name: {
      default: '「ヘルプ」・「Q&A」メニュー',
      [Locale.en_US]: 'bM migrate tour',
    },
    description: {
      default: 'bitlock Managerからの移行ツアーが表示されます。',
      [Locale.en_US]: 'You will be able to check the changes in the migration from bitlock Manager (bM) to workhub.',
    },
  },
  [Feature.ThirdPlace]: {
    name: {
      default: 'ThirdPlace',
      [Locale.en_US]: 'ThirdPlace',
    },
    description: {
      default: 'ThirdPlaceの機能を使用可能になります。',
      [Locale.en_US]: 'The ThirdPlace feature will be available.',
    },
  },
  [Feature.Report]: {
    name: {
      default: 'レポート',
      [Locale.en_US]: 'Report',
    },
    description: {
      default: 'workhubに関するレポートが管理できます。',
      [Locale.en_US]: 'You can manage reports about workhub.',
    },
  },
  [Feature.AboutBitkey]: {
    name: {
      default: 'Bitkeyについて',
      [Locale.en_US]: 'About Bitkey',
    },
    description: {
      default: '「会社概要」「利用規約」「プライバシーポリシー」「ホワイトペーパー」メニューにアクセスできます。',
      [Locale.en_US]: 'You can view information about Bitkey.',
    },
  },
  [Feature.V2BitlockApp]: {
    name: {
      default: 'bitlockアプリ',
      [Locale.en_US]: 'bitlock APP',
    },
    description: {
      default: 'bitlockアプリから組織に関する機能を利用できます',
      [Locale.en_US]: 'You can use the functions related to organization from the bitlock app.',
    },
  },
  [Feature.V2ReceptionApp]: {
    name: {
      default: 'workhubReceptionアプリ',
      [Locale.en_US]: 'workhub Reception App',
    },
    description: {
      default: 'workhubReceptionアプリで受付機能を利用できます。',
      [Locale.en_US]: 'The reception function is available in the workhub Reception app.',
    },
  },
  [Feature.V2WorkhubAppOrganization]: {
    name: {
      default: '組織メンバーの閲覧',
      [Locale.en_US]: 'See Member',
    },
    description: {
      default: 'workhubアプリで組織メンバーを参照することができます。',
      [Locale.en_US]: 'You can find your organization members in the workhub app.',
    },
  },
  [Feature.WorkhubAppPersonalizedNfcCard]: {
    name: {
      default: '個人所有のセキュリティカード管理',
      [Locale.en_US]: 'Personal NFC card management',
    },
    description: {
      default:
        'workhubアプリでユーザー自身が所有するセキュリティカードの登録・管理ができます。\n' +
        '※登録できる枚数の上限は以下にて設定できます。\n' +
        '設定 > 組織設定 > 社員証登録可能枚数制限',
      [Locale.en_US]:
        'Users will be able to register, change, and delete their own NFC cards in the workhub application.',
    },
  },
  [Feature.InviteMailSettingManager]: {
    name: {
      default: '「会員招待メール設定」メニュー',
      [Locale.en_US]: 'Member Invitation Mail Settings',
    },
    description: {
      default: '会員招待メールを編集できます。',
      [Locale.en_US]: 'You can change to member invite mail.',
    },
  },
  [Feature.ThirdPlaceBceSetting]: {
    name: {
      default: 'BCE設定',
      [Locale.en_US]: 'BCE setting',
    },
    description: {
      default:
        '[Bitkey社員専用]BCEとworkhubを接続するための設定です。必ずbitkey社員のアカウントでログインしてください。',
      [Locale.en_US]: 'You can change to invite mail.',
    },
  },
  [Feature.ForBitkey]: {
    name: {
      default: 'For Bitkey',
      [Locale.en_US]: 'For Bitkey',
    },
    description: {
      default:
        '[Bitkey社員専用]お知らせの投稿と編集及びマニュアルの投稿及び編集ができます。必ずBitkey社員のアカウントでログインしてください。',
      [Locale.en_US]:
        'You can post and edit the notification and manual. Be sure to log in with your bitkey employee account.',
    },
  },
  [Feature.V2Dashboard]: {
    // 分かりやすさ優先でメニュー名に合わせる
    name: {
      default: '「スペース利用レポート」メニュー',
      [Locale.en_US]: 'Space Usage Report',
    },
    description: {
      default: 'スペースの利用状況や利用層などのデータが閲覧できます。',
      [Locale.en_US]: 'You can view data on the usage of each space.',
    },
  },
  [Feature.V2MultiTenantOwner]: {
    name: {
      default: 'V2マルチテナント オーナー',
      [Locale.en_US]: 'V2 Multi Tenant Owner',
    },
    description: {
      default: 'マルチテナントのオーナー組織です',
      [Locale.en_US]: 'Multi-tenant owner organization.',
    },
  },
  [Feature.V2MultiTenantOwnerContract]: {
    name: {
      default: '「ビルテナント契約」メニュー',
      [Locale.en_US]: 'Multi-tenant Contract',
    },
    description: {
      default: '契約情報や契約ステータスの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage contract information and contract status.',
    },
  },
  [Feature.V2MultiTenantOwnerContractPlan]: {
    name: {
      default: '「ビルテナント契約プラン」メニュー',
      [Locale.en_US]: 'Multi-tenant Contract Plan',
    },
    description: {
      default: 'ビルテナント契約プランの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage contract plan information and contract plan status.',
    },
  },
  [Feature.LocationInformationCSVOutput]: {
    name: {
      default: '現在地情報のCSV出力',
      [Locale.en_US]: 'CSV output of current location information',
    },
    description: {
      default: '従業員の現在地情報に関するCSVを出力できます。',
      [Locale.en_US]: 'Enables output of CSV regarding employee location information.',
    },
  },
  [Feature.Application]: {
    name: {
      default: '「承認トレイ」メニュー',
      [Locale.en_US]: 'Application Management',
    },
    description: {
      default: '申請の閲覧や承認ができます',
      [Locale.en_US]: 'Allows you to view and approve applications.',
    },
  },
  [Feature.SecuritySetting]: {
    name: {
      default: 'IPアドレス設定',
      [Locale.en_US]: 'Security Setting',
    },
    description: {
      default: 'IPアドレスに関する設定ができます',
      [Locale.en_US]: 'You can configure security settings.',
    },
  },
  [Feature.SpaceAuthoritySetting]: {
    name: {
      default: 'スペースアクセス権設定',
      [Locale.en_US]: 'Space control',
    },
    description: {
      default: '権限別にスペースの閲覧を制御できます',
      [Locale.en_US]: 'Space viewing can be controlled by permissions',
    },
  },
  [Feature.WorkBooth]: {
    name: {
      default: '「ワークブース」メニュー',
      [Locale.en_US]: 'Work Booth',
    },
    description: {
      default: 'ワークブースの情報を登録・管理できます',
      [Locale.en_US]: 'You can check and configure work booths.',
    },
  },
  [Feature.AzbilCloudOperation]: {
    name: {
      default: 'AzbilCloud操作',
      [Locale.en_US]: 'AzbilCloud Operation',
    },
    description: {
      default: 'AzbilCloudを介して機器を登録・管理ができます。',
      [Locale.en_US]: 'You can operate instruments via AzbilCloud.',
    },
  },
  [Feature.TabletOperation]: {
    name: {
      default: 'デバイスの再起動',
      [Locale.en_US]: 'Reboot devices',
    },
    description: {
      default: 'レセプション・RoomSupportが動作しなくなった場合などに、デバイスを遠隔で再起動することができます。',
      [Locale.en_US]: 'You can reboot devices remotely when Reception/RoomSupport are disabled',
    },
  },
  [Feature.UnlockLog]: {
    name: {
      default: '「解施錠履歴」メニュー',
      [Locale.en_US]: 'Unlock Log',
    },
    description: {
      default: 'デバイスごとの解施錠履歴が閲覧できます。',
      [Locale.en_US]: 'Unlocking logs for each device can be viewed.',
    },
  },
  [Feature.ReservationLog]: {
    name: {
      default: '「利用履歴」メニュー',
      [Locale.en_US]: 'Reservation Log',
    },
    description: {
      default: '会議室・ワークスポットなどのエリアの予約ログが閲覧できます。',
      [Locale.en_US]: 'View reservation logs for meeting rooms, workspaces, and other areas.',
    },
  },
  [Feature.SendMailJob]: {
    name: {
      default: '「メール送信履歴」メニュー',
      [Locale.en_US]: 'Send Mail Job',
    },
    description: {
      default: 'workhubから送信したメールの送信履歴を閲覧できます。',
      [Locale.en_US]: 'You can view the sent history of sent e-mails.',
    },
  },
  [Feature.AppKeys]: {
    name: {
      default: '「アプリカギ配信状況」メニュー',
      [Locale.en_US]: 'AppKeys',
    },
    description: {
      default: 'アプリ(Bluetooth)カギの送信状況を閲覧できます',
      [Locale.en_US]: 'You can view the status of your App key transmission.',
    },
  },
  [Feature.FaceRecognitionStatus]: {
    name: {
      default: '「顔情報配信状況」メニュー',
      [Locale.en_US]: 'Face Recognition Status',
    },
    description: {
      default: '顔情報の配信状況と実行履歴が閲覧できます。',
      [Locale.en_US]: 'You can view the delivery status and execution history of face information',
    },
  },
  [Feature.EventReservationParticipants]: {
    name: {
      default: '「イベント参加者予約」メニュー',
      [Locale.en_US]: 'Event Reservation participants',
    },
    description: {
      default: 'イベントの予約ができます。',
      [Locale.en_US]: 'You can make reservations for events.',
    },
  },
  [Feature.EventReservationList]: {
    name: {
      default: '「招待情報一覧」メニュー',
      [Locale.en_US]: 'Event Reservation List',
    },
    description: {
      default: 'イベントの招待状況の閲覧ができます。',
      [Locale.en_US]: 'You can view the status of event invitations.',
    },
  },
  [Feature.EventReservationDashboard]: {
    name: {
      default: '「イベントダッシュボード」メニュー',
      [Locale.en_US]: 'Event Reservation Dashboard',
    },
    description: {
      default: 'イベントの混雑状況を閲覧できます。',
      [Locale.en_US]: 'View event crowds.',
    },
  },
  [Feature.ThirdPlaceContract]: {
    name: {
      default: '「定期利用」メニュー',
      [Locale.en_US]: 'ThirdPlace Contract',
    },
    description: {
      default: '契約情報や契約ステータスの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage contract information and contract status.',
    },
  },
  [Feature.ThirdPlaceContractPlans]: {
    name: {
      default: '「定期利用」メニュー',
      [Locale.en_US]: 'ThirdPlace Contract Plans',
    },
    description: {
      default: '定期利用のプラン登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage your contract plans',
    },
  },
  [Feature.ThirdPlaceUpdateRules]: {
    name: {
      default: '「契約更新ルール」メニュー',
      [Locale.en_US]: 'ThirdPlace Contract Update Rules',
    },
    description: {
      default: '契約更新のタイミングやルールの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage contract renewal timing and rules.',
    },
  },
  [Feature.ThirdPlaceCancelRules]: {
    name: {
      default: '「解約ルール」メニュー',
      [Locale.en_US]: 'ThirdPlace Contract Cancel Rules',
    },
    description: {
      default: '解約ルールの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage cancellation rules.',
    },
  },
  [Feature.ThirdPlaceOptions]: {
    name: {
      default: '「オプションサービス」メニュー',
      [Locale.en_US]: 'ThirdPlace Options',
    },
    description: {
      default: 'オプションサービスの料金プランの管理・登録ができます。',
      [Locale.en_US]: 'You can manage and register fee plans for optional services',
    },
  },
  [Feature.ThirdPlaceBilling]: {
    name: {
      default: '「請求」メニュー',
      [Locale.en_US]: 'ThirdPlace Billing',
    },
    description: {
      default: '請求の確定やデータを管理できます。',
      [Locale.en_US]: 'You can finalize claims and manage data.',
    },
  },
  [Feature.ThirdPlaceReceivedMoney]: {
    name: {
      default: '「入金」メニュー',
      [Locale.en_US]: 'ThirdPlace Received Money',
    },
    description: {
      default: '入金情報の確認や入金データを管理できます。',
      [Locale.en_US]: 'You can check deposit information and manage deposit data.',
    },
  },
  [Feature.ThirdPlaceRefund]: {
    name: {
      default: '「返金」メニュー',
      [Locale.en_US]: 'ThirdPlace Refund',
    },
    description: {
      default: '返金データの管理ができます。',
      [Locale.en_US]: 'Refund data can be managed',
    },
  },
  [Feature.ThirdPlaceClaimCycles]: {
    name: {
      default: '「請求スケジュール」メニュー',
      [Locale.en_US]: 'ThirdPlace Claim Cycles',
    },
    description: {
      default: '請求スケジュールの登録・管理できます。',
      [Locale.en_US]: 'Billing schedules can be registered and managed.',
    },
  },
  [Feature.ThirdPlaceInvoice]: {
    name: {
      default: '「帳票設定」メニュー',
      [Locale.en_US]: 'ThirdPlace Sender',
    },
    description: {
      default: '帳票に表示する発行元や、請求書の振込先口座を管理できます。',
      [Locale.en_US]:
        'You can manage the issuing source to be displayed on forms and the account to which invoices are to be transferred.',
    },
  },
  [Feature.ThirdPlaceGuest]: {
    name: {
      default: '「会員」メニュー',
      [Locale.en_US]: 'ThirdPlace Guest',
    },
    description: {
      default: '会員の情報を登録・管理できます。',
      [Locale.en_US]: 'Register and manage ThirdPlace member information',
    },
  },
  [Feature.ThirdPlaceCustomer]: {
    name: {
      default: '「顧客」メニュー',
      [Locale.en_US]: 'ThirdPlace Customer',
    },
    description: {
      default: '顧客の情報を登録・管理できます。',
      [Locale.en_US]: 'Register and manage ThirdPlace customer information',
    },
  },
  [Feature.ThirdPlacePaymentMethod]: {
    name: {
      default: '「決済設定」メニュー',
      [Locale.en_US]: 'ThirdPlace Payment Method',
    },
    description: {
      default: '支払い方法や振替用口座など、決済に関する情報を管理できます。',
      [Locale.en_US]:
        'You can manage information related to payments, such as payment methods and accounts for transfers.',
    },
  },
  [Feature.ThirdPlacePassSite]: {
    name: {
      default: '「workhub Pass設定」メニュー',
      [Locale.en_US]: 'ThirdPlace PassSite Settings',
    },
    description: {
      default: 'workhub Passで表示される項目の設定ができます。',
      [Locale.en_US]: 'You can configure which items are displayed in workhub Pass',
    },
  },
  [Feature.DataImport]: {
    name: {
      default: '「データインポート」メニュー',
      [Locale.en_US]: 'Data Import',
    },
    description: {
      default: '契約情報や予約データなどを一括でインポートできます。',
      [Locale.en_US]: 'You can import contract information and reservation data in bulk.',
    },
  },
  [Feature.NotificationSetting]: {
    name: {
      default: '「通知設定」メニュー',
      [Locale.en_US]: 'Notification Setting',
    },
    description: {
      default: '通知に関する設定ができます。',
      [Locale.en_US]: 'You can configure settings related to notifications.',
    },
  },
  [Feature.WorkhubAppDeviceSetting]: {
    name: {
      default: 'デバイス権限',
      [Locale.en_US]: 'Device setting authority',
    },
    description: {
      default: '各デバイスの設置やアップデート時に必要になる権限を設定できます。',
      [Locale.en_US]: 'You can set the permissions required for each device installation and update',
    },
  },
  [Feature.WorkhubAppLinkageDeviceSetting]: {
    name: {
      default: 'デバイス連携',
      [Locale.en_US]: 'Linkage device setting',
    },
    description: {
      default: '他のデバイスと連携させることができます。',
      [Locale.en_US]: 'Can be linked to other devices.',
    },
  },
  [Feature.WorkhubAppInstallBitlockPro2]: {
    name: {
      default: 'デバイス設置',
      [Locale.en_US]: 'Install bitlock series',
    },
    description: {
      default: 'bitlockを設置できます。',
      [Locale.en_US]: 'bitlock can be installed.',
    },
  },
  [Feature.WorkhubAppInstallBitlockGate]: {
    name: {
      default: 'デバイス設置',
      [Locale.en_US]: 'Install bitlock series',
    },
    description: {
      default: '',
      [Locale.en_US]: '',
    },
  },
  [Feature.WorkhubAppUninstallBitlockSeries]: {
    name: {
      default: 'アンインストール',
      [Locale.en_US]: 'Uninstall bitlock',
    },
    description: {
      default: 'bitlockをアンインストールできます。',
      [Locale.en_US]: 'bitlock can be uninstalled.',
    },
  },
  [Feature.WorkhubAppFirmwareUpdateBitlockSeries]: {
    name: {
      default: 'ファームウェアアップデート',
      [Locale.en_US]: 'Firmware update',
    },
    description: {
      default: 'bitlockのファームウェアをアップデートできます。',
      [Locale.en_US]: 'bitlock firmware can be updated.',
    },
  },
  [Feature.WorkhubAppViewLogsBitlockSeries]: {
    name: {
      default: 'ログ閲覧',
      [Locale.en_US]: 'View bitlock logs',
    },
    description: {
      default: 'bitlockのログを閲覧できます。',
      [Locale.en_US]: 'You can view bitlock logs.',
    },
  },
  [Feature.WorkhubAppSetUnlockPositionBitlockSeries]: {
    name: {
      default: '解錠位置設定',
      [Locale.en_US]: 'Unlock position setting',
    },
    description: {
      default: 'カギのつまみの解錠位置を設定できます。',
      [Locale.en_US]: 'Unlock position of the key knob can be set.',
    },
  },
  [Feature.WorkhubAppAutoLockSettingBitlockSeries]: {
    name: {
      default: 'オートロック設定',
      [Locale.en_US]: 'Auto lock setting',
    },
    description: {
      default: '閉扉または解錠後指定したタイミングで自動的に施錠するよう設定できます。',
      [Locale.en_US]: 'Can be set to automatically lock the door at a specified time after it is closed or unlocked.',
    },
  },
  [Feature.WorkhubAppTouchSensorSettingBitlockSeries]: {
    name: {
      default: 'タッチセンサー設定',
      [Locale.en_US]: 'Touch sensor setting',
    },
    description: {
      default: 'タッチセンサーの有効・無効を切り替えできます。',
      [Locale.en_US]: 'Touch sensor can be enabled or disabled.',
    },
  },
  [Feature.WorkhubAppInstallBitreaderPlus]: {
    name: {
      default: 'デバイス設置',
      [Locale.en_US]: 'Install bitreader',
    },
    description: {
      default: 'bitreaderを設置できます。',
      [Locale.en_US]: 'bitreader can be installed.',
    },
  },
  [Feature.WorkhubAppUninstallBitreaderSeries]: {
    name: {
      default: 'アンインストール',
      [Locale.en_US]: 'Uninstall bitreader',
    },
    description: {
      default: 'bitreaderをアンインストールできます。',
      [Locale.en_US]: 'bitreader can be uninstalled.',
    },
  },
  [Feature.WorkhubAppFirmwareUpdateBitreaderSeries]: {
    name: {
      default: 'ファームウェアアップデート',
      [Locale.en_US]: 'Firmware update',
    },
    description: {
      default: 'bitreaderのファームウェアをアップデートできます。',
      [Locale.en_US]: 'bitreader firmware can be updated.',
    },
  },
  [Feature.WorkhubAppViewLogsBitreaderSeries]: {
    name: {
      default: 'ログ閲覧',
      [Locale.en_US]: 'View bitreader logs',
    },
    description: {
      default: 'bitreaderのログを閲覧できます。',
      [Locale.en_US]: 'You can view bitreader logs.',
    },
  },
  [Feature.WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries]: {
    name: {
      default: '解施錠操作設定',
      [Locale.en_US]: 'unlocking operation setting',
    },
    description: {
      default: 'bitreaderを利用した施錠操作を設定できます。',
      [Locale.en_US]: 'locking operation can be set up using bitreader.',
    },
  },
  [Feature.WorkhubAppEnableNfcSettingBitreaderSeries]: {
    name: {
      default: 'NFCリーダー設定',
      [Locale.en_US]: 'Enable NFC reader setting',
    },
    description: {
      default: 'NFCリーダーの有効・無効を切り替えできます。',
      [Locale.en_US]: 'NFC reader can be set up enable or disable.',
    },
  },
  [Feature.WorkhubAppEnableKeyPadSettingBitreaderSeries]: {
    name: {
      default: 'キーパッド設定',
      [Locale.en_US]: 'Enable Keypad setting',
    },
    description: {
      default: 'キーパッドの有効・無効を切り替えできます。',
      [Locale.en_US]: 'Keypad can be set up enable or disable.',
    },
  },
  [Feature.WorkhubAppEnableLedSettingBitreaderSeries]: {
    name: {
      default: 'LED設定',
      [Locale.en_US]: 'Enable LED setting',
    },
    description: {
      default: 'LEDの有効・無効を切り替えできます。',
      [Locale.en_US]: 'LED can be set up enable or disable.',
    },
  },
  [Feature.WorkhubAppBatteryAlertSettingBitreaderSeries]: {
    name: {
      default: '電池残量低下時の警告設定',
      [Locale.en_US]: 'Low battery alert setting',
    },
    description: {
      default: '電池残量低下時の警告の有効・無効を切り替えできます。',
      [Locale.en_US]: 'Low battery alert can be set up enable or disable.',
    },
  },
  [Feature.WorkhubAppPasscodeSettingBitreaderSeries]: {
    name: {
      default: 'パスコード設定(固定・ワンタイム)',
      [Locale.en_US]: 'Passcode setting',
    },
    description: {
      default: '固定パスコードとワンタイムパスコードを登録・管理できます。',
      [Locale.en_US]: 'Fixed and one-time passcode can be registered and managed.',
    },
  },
  [Feature.WorkhubAppInstallBitlinkSeries]: {
    name: {
      default: 'デバイス設置',
      [Locale.en_US]: 'Install bitlink',
    },
    description: {
      default: 'bitlinkを設置できます。',
      [Locale.en_US]: 'bitlink can be installed.',
    },
  },
  [Feature.WorkhubAppUninstallBitlinkSeries]: {
    name: {
      default: 'アンインストール',
      [Locale.en_US]: 'Uninstall bitlink',
    },
    description: {
      default: 'bitlinkをアンインストールできます。',
      [Locale.en_US]: 'bitlink can be uninstalled.',
    },
  },
  [Feature.WorkhubAppFirmwareUpdateBitlinkSeries]: {
    name: {
      default: 'ファームウェアアップデート',
      [Locale.en_US]: 'firmware update',
    },
    description: {
      default: 'bitlinkのファームウェアをアップデートできます。',
      [Locale.en_US]: 'bitlink firmware can be updated.',
    },
  },
  [Feature.WorkhubAppEnableBeaconSettingBitlinkSeries]: {
    name: {
      default: 'Beacon設定',
      [Locale.en_US]: 'Beacon setting',
    },
    description: {
      default: 'Beaconの有効・無効を切り替えできます。',
      [Locale.en_US]: 'Beacon can be set up enable or disable.',
    },
  },
  [Feature.WorkhubAppChangeWiFiConnectionSettingBitlinkSeries]: {
    name: {
      default: 'Wi-Fi接続先設定',
      [Locale.en_US]: 'Wi-Fi connection setting',
    },
    description: {
      default: 'Wi-Fiの接続先を設定できます。',
      [Locale.en_US]: 'You can set the Wi-Fi connection destination.',
    },
  },
  [Feature.DropInPlans]: {
    name: {
      default: '「ドロップイン」メニュー',
      [Locale.en_US]: 'Drop-in',
    },
    description: {
      default: 'ドロップインのプラン登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage drop-in information and drop-in status.',
    },
  },
  [Feature.DropInPricePlans]: {
    name: {
      default: '「料金プラン」メニュー',
      [Locale.en_US]: 'Price plan',
    },
    description: {
      default: 'ドロップインの料金プランの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage drop-in price plan information and drop-in price plan status.',
    },
  },
  [Feature.AntiPassBackArea]: {
    name: {
      default: '「アンチパスバックエリア」メニュー',
      [Locale.en_US]: 'Anti Pass Back Area',
    },
    description: {
      default: 'アンチパスバックエリアの登録・管理ができます。',
      [Locale.en_US]: 'You can register and manage anti-pass-back information and anti-pass-back status.',
    },
  },
  [Feature.AntiPassBackAlert]: {
    name: {
      default: '「アンチパスバックアラート」メニュー',
      [Locale.en_US]: 'Anti Pass Back Alert',
    },
    description: {
      default: 'アンチパスバックアラートの管理ができます。',
      [Locale.en_US]: 'You can manage anti-pass-back alert information and anti-pass-back alert status.',
    },
  },
  [Feature.FaceRegistration]: {
    name: {
      default: '顔情報の一括登録',
      [Locale.en_US]: 'Face registration',
    },
    description: {
      default: '顔情報の一括登録をすることができます。',
      [Locale.en_US]: 'You can face registration.',
    },
  },
};

export class AuthorityService {
  private constructor() {}

  private static instance: AuthorityService;

  public static getInstance = () => {
    if (!AuthorityService.instance) {
      AuthorityService.instance = new AuthorityService();
    }
    return AuthorityService.instance;
  };
  public getAuthorityPatterns = () => ApiAccountAuthorityPattern.getAll();
  public getDictDef = () => authorityDictDef;
  public addAuthority = (data: {code: string; nameJp: string; nameEn: string; authority: FeatureAuthority}) =>
    ApiAccountAuthorityPattern.add(data);
  public updateAuthority = (
    id: string,
    data: {
      code: string;
      nameJp: string;
      nameEn: string;
      authority: FeatureAuthority;
    }
  ) => ApiAccountAuthorityPattern.update(id, data);
  public removeAuthority = (id: string) => ApiAccountAuthorityPattern.deleteById(id);
}
