import {createSlice} from '@reduxjs/toolkit';
import {AlgoliaState} from '../state-types/algoliaState';

const initialState: AlgoliaState = {
  apiKey: '',
  applicationId: '',
  organizationId: '',
};

const algoliaSlice = createSlice({
  name: 'algolia',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<AlgoliaState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

export default algoliaSlice;
