import {Firestore} from '../firebase-firestore';
import {CollectionOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';

const ref = (orgId: string) => Firestore.getInstance().getCollectionRef(CollectionOrgChargeDetail(orgId));

export class FirestoreOrgChargeDetail {
  public static ref = (organizationId: string) => ref(organizationId);
  public static getDataById = (organizationId: string, id: string) =>
    Firestore.getInstance().getById(ref(organizationId), id);
  public static getDataByIds = (organizationId: string, ids: string[]) =>
    Firestore.getInstance().getByIds(ref(organizationId), ids);
  public static getAll = (organizationId: string) => Firestore.getInstance().getByQuery(ref(organizationId));
}
