// noinspection JSUnusedGlobalSymbols

import {createSvgIcon} from '@mui/material/utils';

export const Downward = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 3a1 1 0 0 1 1 1v13.586l6.293-6.293a1 1 0 0 1 1.414 1.414l-8 8a1 1 0 0 1-1.414 0l-8-8a1 1 0 1 1 1.414-1.414L11 17.586V4a1 1 0 0 1 1-1'
      clipRule='evenodd'
    />
  </svg>,
  'Downward'
);

export const Upward = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11.293 3.293a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1-1.414 1.414L13 6.414V20a1 1 0 1 1-2 0V6.414l-6.293 6.293a1 1 0 0 1-1.414-1.414z'
      clipRule='evenodd'
    />
  </svg>,
  'Upward'
);

export const Back = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12.707 3.293a1 1 0 0 1 0 1.414L6.414 11H20a1 1 0 1 1 0 2H6.414l6.293 6.293a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 0 1 1.414 0'
      clipRule='evenodd'
    />
  </svg>,
  'Back'
);

export const Forward = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11.293 3.293a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414-1.414L17.586 13H4a1 1 0 1 1 0-2h13.586l-6.293-6.293a1 1 0 0 1 0-1.414'
      clipRule='evenodd'
    />
  </svg>,
  'Forward'
);

export const Outside = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M16.3 13.275a.95.95 0 0 1-.275-.7q0-.425.275-.7L18.175 10H12a.97.97 0 0 1-.712-.288A.97.97 0 0 1 11 9q0-.425.288-.713A.97.97 0 0 1 12 8h6.175L16.3 6.125q-.275-.275-.275-.713T16.3 4.7a.95.95 0 0 1 .7-.275q.425 0 .7.275l3.6 3.6q.15.15.213.325.062.176.062.375 0 .2-.062.375a.9.9 0 0 1-.213.325l-3.6 3.6a.9.9 0 0 1-.7.262 1 1 0 0 1-.7-.287M6.3 19.3l-3.6-3.6a.9.9 0 0 1-.212-.325A1.1 1.1 0 0 1 2.425 15a1.1 1.1 0 0 1 .063-.375.9.9 0 0 1 .212-.325l3.6-3.6a.95.95 0 0 1 .7-.275q.425 0 .7.275.275.274.275.712t-.275.713L5.825 14H12a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 15q0 .424-.287.712A.97.97 0 0 1 12 16H5.825L7.7 17.875a.95.95 0 0 1 .275.7.95.95 0 0 1-.275.7 1 1 0 0 1-.7.287.9.9 0 0 1-.7-.262'
    />
  </svg>,
  'Outside'
);

export const Inside = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='m15.3 13.3-3.6-3.6a.9.9 0 0 1-.212-.325A1.1 1.1 0 0 1 11.425 9q0-.2.063-.375A.9.9 0 0 1 11.7 8.3l3.6-3.6q.3-.3.7-.3t.7.3.3.712q0 .413-.3.713L14.825 8H21q.424 0 .712.287Q22 8.575 22 9q0 .424-.288.712A.97.97 0 0 1 21 10h-6.175l1.875 1.875q.3.3.3.7t-.3.7-.687.325q-.388.026-.713-.3m-8 5.975q.3.3.7.312t.7-.287l3.6-3.6q.15-.15.213-.325.062-.175.062-.375t-.062-.375a.9.9 0 0 0-.213-.325l-3.6-3.6a.96.96 0 0 0-.7-.3q-.4 0-.7.3t-.3.712.3.713L9.175 14H3a.97.97 0 0 0-.712.287A.97.97 0 0 0 2 15q0 .424.288.712A.97.97 0 0 0 3 16h6.175L7.3 17.875q-.3.3-.3.7t.3.7'
    />
  </svg>,
  'Inside'
);
