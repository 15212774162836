// noinspection JSUnusedGlobalSymbols

import {createSvgIcon} from '@mui/material/utils';

export const Employee = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M13 5.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0M12.202 12c-.98-.322-2.063-.5-3.202-.5-4.418 0-8 2.686-8 6 0 .59.53 1 1.119 1H9.5V14a2 2 0 0 1 2-2zM18.5 19a1 1 0 1 0 0-2h-3a1 1 0 1 0 0 2z' />
      <path
        fillRule='evenodd'
        d='M18 13.5a1 1 0 1 0-2 0h-3a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2zm-5 2h3a1 1 0 1 0 2 0h3v5h-8z'
        clipRule='evenodd'
      />
    </g>
  </svg>,
  'Employee'
);

export const Supplier = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M11.008 6.062h2.975l3.262-3.269a.99.99 0 0 1 1.408 0l2.559 2.565a.99.99 0 0 1 0 1.397L18.94 9.033h-7.933v1.981a.99.99 0 0 1-.991.991.99.99 0 0 1-.992-.99V8.042a1.98 1.98 0 0 1 1.983-1.981M5.06 11.014v3.963l-2.27 2.268a.99.99 0 0 0 0 1.396l2.558 2.566a.99.99 0 0 0 1.408 0l4.253-4.25h3.967a.99.99 0 0 0 .991-.99v-.99h.992a.99.99 0 0 0 .991-.991v-.99h.992a.99.99 0 0 0 .992-.991v-.99h-6.941v.99a1.98 1.98 0 0 1-1.984 1.981H9.025a1.984 1.984 0 0 1-1.983-1.981V9.033z'
    />
  </svg>,
  'Supplier'
);

export const Partner = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m7.46 7.12-2.78 1.15a4.98 4.98 0 0 0-2.95-2.94l1.15-2.78c2.1.8 3.77 2.47 4.58 4.57M12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3M9.13 4.54l1.17 2.78a5 5 0 0 0-2.98 2.97L4.54 9.13a7.98 7.98 0 0 1 4.59-4.59M4.54 14.87l2.78-1.15a4.97 4.97 0 0 0 2.97 2.96l-1.17 2.78a8 8 0 0 1-4.58-4.59m10.34 4.59-1.15-2.78a4.98 4.98 0 0 0 2.95-2.97l2.78 1.17a8 8 0 0 1-4.58 4.58'
    />
  </svg>,
  'Partner'
);

export const Guest = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M12 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2m-6 8h12v3H6zm14-8h-4V3H8v3H4a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2M10 5h4v5h-4zm10 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8h4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2h4z'
    />
  </svg>,
  'Guest'
);
