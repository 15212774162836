import {createSlice} from '@reduxjs/toolkit';
import {RolesState} from '../state-types/rolesState';

const initialState: RolesState = {
  roles: [],
  loaded: false,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    set: (state, action: {payload: RolesState}) => action.payload,
    clear: () => initialState,
  },
});

export default rolesSlice;
