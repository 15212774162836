import dayjs from 'dayjs';
import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {ContractState} from './WContractCreateTypes';

export const initialContractInfo: ContractState = {
  contractTerm: {startAt: dayjs().startOf('d').valueOf()},
  conclusionDate: Date.now(),
  plans: [],
  selectedOptions: [],
  employeeContractors: [],
  type: 'business', //TODO：個人開始後に修正
  corporateInfo: {
    name: '',
    firstNameJp: '',
    familyNameJp: '',
  },
  representativeInfo: {
    name: '',
    familyNameJp: '',
    firstNameJp: '',
    email: '',
    identificationImageData: [],
  },
  corporateRegistrationFiles: [],
  inChargeContractorInfo: [],
  billingNotifications: [],
  members: [{name: '', familyNameJp: '', firstNameJp: '', email: ''}],
  billTo: {
    name: '',
    familyNameJp: '',
    firstNameJp: '',
    email: '',
  },
  calcChargeDetails: [],
  allClaimCycles: [],
};

export enum CONTRACT_CREATE_ERROR_TYPE {
  contract_conclusion_date = 'contract_conclusion_date',
  contract_term_invalid = 'contract_term_invalid',
  contract_initial_bill_date_input_invalid = 'contract_initial_bill_date_input_invalid',
  contract_initial_payment_deadline_input_invalid = 'contract_initial_payment_deadline_input_invalid',
  contract_representative_input_invalid = 'contract_representative_input_invalid',
  contract_refund_bank_input_invalid = 'contract_refund_bank_input_invalid',
  contract_bill_bank_input_invalid = 'contract_bill_bank_input_invalid',
  contract_bank_input_invalid = 'contract_bank_input_invalid',
  contract_charge_people_input_invalid = 'contract_charge_people_input_invalid',
  contract_notification_input_invalid = 'contract_notification_input_invalid',
  contract_billing_business_input_invalid = 'contract_billing_business_input_invalid',
  contract_base_input_invalid = 'contract_base_input_invalid',
  contract_plan_input_invalid = 'contract_plan_input_invalid',
  conclusion_date_input_invalid = 'conclusion_date_input_invalid',
  invalid_contract_plan_area_invalid = 'invalid_contract_plan_area_invalid',
  contract_business_input_invalid = 'contract_business_input_invalid',
  contract_customer_input_invalid = 'contract_customer_input_invalid',
  contract_option_input_invalid = 'contract_option_input_invalid',
  contract_corporate_input_invalid = 'contract_corporate_input_invalid',
  contract_billing_customer_input_invalid = 'contract_billing_customer_input_invalid',
  contract_initial_billing_cycle_missing = 'contract_initial_billing_cycle_missing',
  contract_need_select_space = 'contract_need_select_space',
  target_org_id_not_set = 'target_org_id_not_set',
  contract_customer_role_invalid = 'contract_customer_role_invalid',
  contract_charge_detail_invalid = 'contract_charge_detail_invalid',
  has_both_tax_div_charges = 'has_both_tax_div_charges',
}

export type ContractControl = {
  type: 'create' | 'renew';
  // 画面の制御に必要な項目
  errorList: CONTRACT_CREATE_ERROR_TYPE[];
  isInviteMailSetting?: boolean;
  initialized?: boolean;
};

export const ContractContext = createContext<{
  state: ContractState;
  setState: Dispatch<SetStateAction<ContractState>>;
  controlState: ContractControl;
  setControlState: Dispatch<SetStateAction<ContractControl>>;
}>({
  state: {...initialContractInfo, currentContract: {...initialContractInfo}},
  setState: () => {},
  controlState: {
    errorList: [],
    isInviteMailSetting: false,
    type: 'create',
  },
  setControlState: () => {},
});

export const useContractState = () => useContext(ContractContext);
