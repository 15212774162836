import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import * as React from 'react';
import {ReactNode, useCallback, useState} from 'react';
import styled from '@emotion/styled';
import {makeStyles} from 'tss-react/mui';
import {shadowColor, WHColor} from '@/common/styles/whColor';

interface P {
  contents?: ReactNode;
  // 最初に表示するアイコン
  firstDisplayIcon: ReactNode;
  // header
  header?: ReactNode;
  // headerに表示するアイコン
  headerIcon?: ReactNode;
  // 詳細とか。ボタンのイメージ。
  bottomContents?: ReactNode;
  onClickBottomButton?: () => any;
  backgroundColor?: string;
}

const PopoverArea = styled.div``;
const InfoLine = styled.div`
  color: ${WHColor.text.neutralSecondary};
  font-size: 12px;
  white-space: pre-wrap;
`;

const ContentsArea = styled.div`
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderIconWrapper = styled.div`
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles()(() => ({
  bottomButton: {
    fontSize: 12,
    color: WHColor.text.semanticError,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 48,
  },
  popper: {
    backgroundColor: WHColor.surface.neutralLow,
    boxShadow: `0px 5px 5px -3px ${shadowColor.shadowAlpha020}, 0px 8px 10px 1px ${shadowColor.shadowAlpha014}, 0px 3px 14px 2px ${shadowColor.shadowAlpha012}`,
    borderRadius: 4,
    zIndex: 10000,
  },
}));

const WInformationPopper = React.memo(function WInformationPopper(props: P) {
  const {contents, header, headerIcon, firstDisplayIcon, bottomContents, onClickBottomButton} = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const {classes} = useStyles();
  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDisplayPopover = useCallback(async e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onClick = useCallback(async () => {
    if (onClickBottomButton) {
      await onClickBottomButton();
    }
    setAnchorEl(null);
  }, [onClickBottomButton]);

  const stopPropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <div onMouseLeave={handlePopoverClose} onClick={stopPropagation}>
        <div style={{display: 'flex', alignItems: 'center'}} onMouseOver={onDisplayPopover}>
          {firstDisplayIcon}
        </div>
        <Popper className={classes.popper} open={!!anchorEl} anchorEl={anchorEl}>
          <PopoverArea onMouseLeave={handlePopoverClose}>
            {header && (
              <>
                <Header>
                  <HeaderIconWrapper>{headerIcon}</HeaderIconWrapper>
                  <div>{header}</div>
                </Header>
                <Divider />
              </>
            )}

            <ContentsArea>
              <InfoLine>{contents}</InfoLine>
            </ContentsArea>

            {bottomContents && onClickBottomButton && (
              <>
                <Divider />
                <MenuItem onClick={onClick} className={classes.bottomButton}>
                  {bottomContents}
                </MenuItem>
              </>
            )}
          </PopoverArea>
        </Popper>
      </div>
    </>
  );
});
export default WInformationPopper;
