import {
  ApiTypesBulkCreateReservationLockerSettingReq,
  ApiTypesCreateReservationSettingReq,
  ApiTypesCreateReservationSettingRes,
  ApiTypesGetOwnerReservationSettingRes,
  ApiTypesGetReservableSpaceSettingSubsetsRes,
  ApiTypesUpdateReservationSettingReq,
  ApiTypesUpdateReservationSettingRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/reservations/reservation-settings/apiTypesOrgReservationSettings';
import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../../../common/firebase/functions/firebase-functions';
import {ApiCoreOrg} from '../../apiCoreOrg';

const basePath = 'reservations/settings';

export class ApiWorkhubCoreReservationSettings {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static get = <Response>(path: string) => ApiCoreOrg.get<Response>(FirebaseFunctions.makePath(basePath, path));

  public static add = (data: ApiTypesCreateReservationSettingReq) =>
    ApiWorkhubCoreReservationSettings.post<ApiTypesCreateReservationSettingRes, ApiTypesCreateReservationSettingReq>(
      '/',
      data
    );

  public static update = (settingId: string, data: ApiTypesUpdateReservationSettingReq) =>
    ApiWorkhubCoreReservationSettings.patch<ApiTypesUpdateReservationSettingRes, ApiTypesUpdateReservationSettingReq>(
      `/${settingId}`,
      data
    );

  public static updateWorkhubSetting = (settingId: string, data: ApiTypesUpdateReservationSettingReq) =>
    ApiWorkhubCoreReservationSettings.patch<ApiTypesUpdateReservationSettingRes, ApiTypesUpdateReservationSettingReq>(
      `/${settingId}/workhubSetting`,
      data
    );

  public static bulkLocker = (createType: 'all' | 'allForce', data: ApiTypesBulkCreateReservationLockerSettingReq) =>
    ApiWorkhubCoreReservationSettings.post<
      ApiTypesCreateReservationSettingRes[],
      ApiTypesBulkCreateReservationLockerSettingReq
    >('/bulk-locker', {
      ...data,
      createType,
    });

  public static bulk = (data: ApiTypesCreateReservationSettingReq[]) =>
    ApiWorkhubCoreReservationSettings.post<
      ApiTypesCreateReservationSettingRes[],
      ApiTypesCreateReservationSettingReq[]
    >('/bulk', data);

  public static getOwnerSetting = (settingId: string) =>
    ApiWorkhubCoreReservationSettings.get<ApiTypesGetOwnerReservationSettingRes>(`/owner-settings/${settingId}`);

  public static getReservableSpaceSettings = () =>
    ApiWorkhubCoreReservationSettings.get<ApiTypesGetReservableSpaceSettingSubsetsRes>(
      '/reservableSpaceSettingSubsets'
    );
}
