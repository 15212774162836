import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ContractPlanService} from '../../../../v2_service/contractPlan/contractPlanService';
import {RootState} from '../../store/rootStateType';
import {WContractPlanScreenState} from '../state/wContractPlanScreenState';

const initialState: WContractPlanScreenState = {
  isOpenDeleteDialog: false,
  isOpenDownloadCsvDialog: false,
  isOpenRegistrationDialog: false,
  isLoading: false,
  selectPlanIds: [],
  viewContractPlan: [],
  masterContractPlans: [],
  isSettingPaymentMethod: true,
  searchWord: '',
  searchFilter: {
    lastUpdatedAt: {},
    sortBy: {orderBy: 'asc', sortByField: 'code'},
  },
};

const contractPlanScreenSlice = createSlice({
  name: 'contractPlanScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    checkSelectPlanId: (state, {payload}: PayloadAction<(ids: string[]) => string[]>) => {
      state.selectPlanIds = payload(state.selectPlanIds);
    },
    resetSelectPlanId: state => {
      state.selectPlanIds = [];
    },
    updateIsOpenDeleteDialog: (state, {payload}: PayloadAction<WContractPlanScreenState['isOpenDeleteDialog']>) => {
      state.isOpenDeleteDialog = payload;
    },
    updateIsOpenRegistrationDialog: (
      state,
      {payload}: PayloadAction<WContractPlanScreenState['isOpenRegistrationDialog']>
    ) => {
      state.isOpenRegistrationDialog = payload;
    },
    updateIsOpenDownloadCsvDialog: (
      state,
      {payload}: PayloadAction<WContractPlanScreenState['isOpenDownloadCsvDialog']>
    ) => {
      state.isOpenDownloadCsvDialog = payload;
    },
    updateIsLoading: (state, {payload}: PayloadAction<WContractPlanScreenState['isLoading']>) => {
      state.isLoading = payload;
    },
    updateViewContractPlan: (state, {payload}: PayloadAction<WContractPlanScreenState['viewContractPlan']>) => {
      state.viewContractPlan = payload;
    },
    updateSearchFilterLastUpdatedAt: (
      state,
      {payload}: PayloadAction<WContractPlanScreenState['searchFilter']['lastUpdatedAt']>
    ) => {
      state.searchFilter = {
        ...state.searchFilter,
        lastUpdatedAt: {
          from: payload.from ?? state.searchFilter.lastUpdatedAt.from,
          to: payload.to ?? state.searchFilter.lastUpdatedAt.to,
        },
      };
    },

    updateIsSettingPaymentMethod: (
      state,
      {payload}: PayloadAction<WContractPlanScreenState['isSettingPaymentMethod']>
    ) => {
      state.isSettingPaymentMethod = payload;
    },
    updateMasterContractPlans: (state, {payload}: PayloadAction<WContractPlanScreenState['masterContractPlans']>) => {
      state.masterContractPlans = payload;
    },
    filterViewContractPlan: (
      state,
      {
        payload,
      }: PayloadAction<{
        isReset?: boolean;
        registerPlan?: WContractPlanScreenState['masterContractPlans'][0];
        searchWord?: string;
        orderBy?: WContractPlanScreenState['searchFilter']['sortBy']['orderBy'];
        clearLastUpdatedAt?: boolean;
      }>
    ) => {
      if (payload.isReset) {
        state.searchWord = initialState.searchWord;
        state.searchFilter = initialState.searchFilter;
        state.viewContractPlan = state.masterContractPlans;
      } else {
        const targetPlans = payload.registerPlan
          ? [payload.registerPlan, ...state.masterContractPlans]
          : state.masterContractPlans;
        const targetSearchWord = payload.searchWord ?? state.searchWord;
        const targetSearchFilter: WContractPlanScreenState['searchFilter'] = {
          ...state.searchFilter,
          lastUpdatedAt: {
            from: payload.clearLastUpdatedAt ? undefined : state.searchFilter.lastUpdatedAt.from,
            to: payload.clearLastUpdatedAt ? undefined : state.searchFilter.lastUpdatedAt.to,
          },
          sortBy: {
            sortByField: state.searchFilter.sortBy.sortByField,
            orderBy: payload.orderBy ?? state.searchFilter.sortBy.orderBy,
          },
        };
        const isAsc = targetSearchFilter.sortBy.orderBy === 'asc';

        // stateの更新
        state.searchWord = targetSearchWord;
        state.searchFilter = targetSearchFilter;
        if (payload.registerPlan) {
          state.masterContractPlans = targetPlans;
        }

        const filteredContractPlans = targetPlans.filter(plan => {
          // 文字列フィルター
          const isMatchSearchWord =
            !targetSearchWord || plan.nameJp?.includes(targetSearchWord) || plan.code?.includes(targetSearchWord);

          // 最終更新日フィルター
          const isMatchLastUpdatedAt =
            (!targetSearchFilter.lastUpdatedAt.from ||
              plan.lastUpdatedBy.timestamp >= targetSearchFilter.lastUpdatedAt.from) &&
            (!targetSearchFilter.lastUpdatedAt.to ||
              plan.lastUpdatedBy.timestamp <= targetSearchFilter.lastUpdatedAt.to);

          return isMatchSearchWord && isMatchLastUpdatedAt;
        });

        state.viewContractPlan = filteredContractPlans.sort((a, b) =>
          a[targetSearchFilter.sortBy.sortByField] > b[targetSearchFilter.sortBy.sortByField]
            ? isAsc
              ? 1
              : -1
            : isAsc
            ? -1
            : 1
        );
      }
    },
    removeContractPlanRowsBySelectPlanIds: state => {
      state.viewContractPlan = state.viewContractPlan.filter(r => !state.selectPlanIds.includes(r.id));
      state.masterContractPlans = state.masterContractPlans.filter(r => !state.selectPlanIds.includes(r.id));
    },
  },
});
export default contractPlanScreenSlice;

export const contractPlanScreenAsyncThunk = {
  deleteContractPlan: createAsyncThunk<void>(
    'contractPlanScreen/deleteContractPlan',
    async (_, {dispatch, getState, rejectWithValue}) => {
      const selectPlanIds = (getState() as RootState).screens.contractPlanScreen.selectPlanIds;
      try {
        await ContractPlanService.deleteByIds({contractPlanIds: selectPlanIds});
        dispatch(contractPlanScreenSlice.actions.removeContractPlanRowsBySelectPlanIds());
        return;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
};
