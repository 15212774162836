import React, {type PropsWithChildren} from 'react';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {downloadCsv} from '../../../common/utils/downloadUtil';

interface P {
  fileName: string;
  getData: () => Promise<CSVDataModel | undefined>;
  onSuccess: () => any;
  onFail: (message: string) => any;
  onClose: () => any;
  setProcessing: Dispatch<SetStateAction<boolean>>;
  disable: boolean;
}

export type CSVDataModel = {
  csvData: string[];
  header: string[];
};

// ダウンロードボタンを押したときに、動的にログ情報をダウンロードしたかったので、
// 分けてコンポーネントを作成しています。
// 別途コンポーネントを分けているのは、今後AlgoliaだけではなくFirestoreからデータを取得してうんたらする可能性があったため、
// デバイス > ログのように毎回データを取得すると処理が遅くなる可能性があるかなと思ったためです。
const WCSVDownloadLink: React.FC<PropsWithChildren<P>> = props => {
  const {fileName, children, getData, onSuccess, onClose, onFail, setProcessing, disable} = props;

  const download = useCallback(async () => {
    if (disable) return;
    try {
      setProcessing(true);
      const csvDataModel = await getData();
      if (!csvDataModel) {
        return;
      }
      const {csvData, header} = csvDataModel;
      // @ts-ignore
      csvData.unshift(header);
      downloadCsv(csvData, fileName);
      onClose();
      onSuccess();
    } catch (e) {
      onClose();
      onFail(e);
    } finally {
      setProcessing(false);
    }
  }, [disable, fileName, getData, onClose, onFail, onSuccess, setProcessing]);

  return <div onClick={download}>{children}</div>;
};

WCSVDownloadLink.defaultProps = {
  disable: false,
};

export default WCSVDownloadLink;
