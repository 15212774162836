import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useState} from 'react';
import useDict from '../../../../../common/hooks/useDict';
import {Locale} from '../../../../../common/redux/state-types/localeStateType';
import WButton from '../../../../button/WButton';
import WCsvDownloadLink, {CSVDataModel} from '../../../../csv/csv-download-link/WCsvDownloadLink';
import {WHFont} from '@/common/styles/whFont';

const dictDef = {
  download: {
    default: {
      default: 'ダウンロード',
      [Locale.en_US]: 'Download',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
};

const WCsvDownloadDialog = React.memo<{
  open: boolean;
  title?: string;
  titleBackground?: string;
  titleColor?: string;
  fileName: string;
  getData: () => Promise<CSVDataModel | undefined>;
  onSuccess: () => any;
  onFail: (e) => any;
  onClose: () => any;
  children: any;
  disabled: boolean;
}>(function WCsvDownloadDialog({
  open,
  title,
  titleBackground,
  titleColor,
  fileName,
  getData,
  onSuccess,
  onFail,
  onClose,
  children,
  disabled,
}) {
  const dict = useDict(dictDef);
  const [processing, setProcessing] = useState<boolean>(false);

  return (
    <Dialog open={open} disableEscapeKeyDown={true}>
      {title && (
        <DialogTitle style={{...WHFont.titleLarge, background: titleBackground, color: titleColor}}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <WButton label={dict.cancel} onClick={onClose} color={'secondary'} disabled={processing} />
        <WCsvDownloadLink
          getData={getData}
          onSuccess={onSuccess}
          onFail={onFail}
          onClose={onClose}
          setProcessing={setProcessing}
          fileName={fileName}
          disable={processing || disabled}
        >
          <WButton label={dict.download} disabled={processing || disabled} color={'primary'} running={processing} />
        </WCsvDownloadLink>
      </DialogActions>
    </Dialog>
  );
});

export default WCsvDownloadDialog;
