// noinspection JSUnusedGlobalSymbols

import {createSvgIcon} from '@mui/material/utils';

export const Workhub = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='#fff'
      d='M20.43 3a2.45 2.45 0 0 0-1.828.773q-.75.774-.75 1.852 0 1.102.75 1.875.75.75 1.828.75a2.46 2.46 0 0 0 1.804-.75q.75-.773.75-1.875 0-1.078-.75-1.852Q21.484 3 20.43 3m0 1.078q.562 0 1.03.492.47.469.47 1.055 0 .633-.47 1.125-.468.47-1.03.469-.61 0-1.102-.469-.468-.492-.469-1.125 0-.585.47-1.055.49-.492 1.1-.492m-7.828 15.867a.38.38 0 0 0 .164.188q.14.07.328.07h.117a.7.7 0 0 0 .328-.07.47.47 0 0 0 .188-.211l3.328-10.734a.6.6 0 0 0 .07-.258.34.34 0 0 0-.07-.211.3.3 0 0 0-.164-.14.7.7 0 0 0-.258-.048h-1.125q-.328 0-.563.07t-.304.258l-1.524 5.063a.45.45 0 0 1-.187.258.55.55 0 0 1-.258.117h-.047a.6.6 0 0 1-.281-.07.7.7 0 0 1-.188-.235l-2.484-5.46a.36.36 0 0 0-.188-.212.6.6 0 0 0-.304-.07h-.164q-.164 0-.305.094a.37.37 0 0 0-.188.187l-2.507 5.461a.46.46 0 0 1-.211.211.5.5 0 0 1-.258.07H5.5a.5.5 0 0 1-.281-.093.5.5 0 0 1-.164-.258L3.53 8.859a.43.43 0 0 0-.21-.234.6.6 0 0 0-.305-.094H1.469a.7.7 0 0 0-.258.047.3.3 0 0 0-.164.14.33.33 0 0 0-.047.188q0 .117.047.281l3.422 10.735a.5.5 0 0 0 .164.21q.14.072.328.071h.14q.165 0 .282-.07a.6.6 0 0 0 .164-.188l3.047-6.632a.5.5 0 0 1 .187-.211.5.5 0 0 1 .281-.094q.141 0 .258.094a.46.46 0 0 1 .211.21z'
    />
  </svg>,
  'Workhub'
);
