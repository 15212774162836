// noinspection JSUnusedGlobalSymbols

import {createSvgIcon} from '@mui/material/utils';

export const Up = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M11.298 8.282A.95.95 0 0 1 12 8q.42 0 .702.282l5.994 6.005a.994.994 0 0 1 0 1.431.95.95 0 0 1-.703.282.95.95 0 0 1-.702-.281L12 10.416 6.709 15.72a.95.95 0 0 1-.702.281.95.95 0 0 1-.703-.281.994.994 0 0 1 0-1.431z'
    />
  </svg>,
  'Up'
);

export const Down = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M5.304 8.282A.95.95 0 0 1 6.007 8q.42 0 .702.282L12 13.584l5.291-5.302A.95.95 0 0 1 17.993 8q.421 0 .703.282a.994.994 0 0 1 0 1.43l-5.994 6.007A.95.95 0 0 1 12 16a.95.95 0 0 1-.702-.281L5.304 9.713a.994.994 0 0 1 0-1.431'
    />
  </svg>,
  'Down'
);

export const Left = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M15.696 5.281a.994.994 0 0 1 0 1.43l-5.287 5.277 5.287 5.3a.994.994 0 0 1 0 1.43.95.95 0 0 1-.702.282.95.95 0 0 1-.702-.281l-5.988-6.004a.993.993 0 0 1 0-1.43l5.988-6.004A.95.95 0 0 1 14.994 5a.95.95 0 0 1 .702.281'
    />
  </svg>,
  'Left'
);

export const Right = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M8.304 5.281a.994.994 0 0 0 0 1.43l5.287 5.277-5.287 5.3a.994.994 0 0 0 0 1.43.95.95 0 0 0 .702.282.95.95 0 0 0 .702-.281l5.988-6.004a.994.994 0 0 0 0-1.43L9.708 5.28A.95.95 0 0 0 9.006 5a.95.95 0 0 0-.702.281'
    />
  </svg>,
  'Right'
);
