import {createSlice} from '@reduxjs/toolkit';
import {UserGroupState} from '../state-types/userGroupState';

const initialState: UserGroupState = {
  userGroups: [],
};

const userGroupSlice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<UserGroupState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

export default userGroupSlice;
