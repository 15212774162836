import {catchStack} from '@/common/error/stacktrace';
import {
  ApiTypesAddActivationsPostRequest,
  ApiTypesDeleteActivationsPostRequest,
} from '@bitkey-service/v2_core-types/lib/api/organization/activations/apiTypesActivations';
import {ActivationGroup} from '../../common/feature-control/featureDefinitions';
import {UserState} from '../../common/redux/state-types/userStateType';
import {ApiActivations} from '../../v2_api/workhub-core/organizations/activations/apiActivations';

export class ActivationService {
  public static add = (data: ApiTypesAddActivationsPostRequest) => ApiActivations.add(data).catch(catchStack());
  public static delete = (data: ApiTypesDeleteActivationsPostRequest) =>
    ApiActivations.delete(data).catch(catchStack());
  public static isActivated = (userActivations: UserState['activations'], activation: ActivationGroup) => {
    return userActivations.find(a => a.activationGroup === activation);
  };

  /**
   * booleanが欲しい場合はこっちを使う
   *
   * @deplicated `FsActivations`を使う方に寄せていきたいので、代わりにsrc/v2_service/activations/hasActivation.ts を使って欲しい。
   */
  public static hasActivation = (userActivations: UserState['activations'], activation: ActivationGroup) => {
    return userActivations.some(a => a.activationGroup === activation);
  };
}
