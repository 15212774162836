import {ScreensState} from './screensState';
import renewContractSlice from './slices/renewContractSlice';
import createContractSlice from './slices/createContractSlice';
import releaseContractPlanSlice from './slices/releaseContractPlanSlice';
import contractDetailSlice from './slices/contractDetailSlice';
import contractPlanScreenSlice from './slices/contractPlanScreenSlice';
import customerDetailSlice from '@/common/redux/screens/slices/customerDetailSlice';
import spaceReportScreenSlice from '@/common/redux/screens/slices/spaceReportScreenSlice';
import {dropInPlansScreenSlice} from '@/common/redux/screens/slices/dropInPlansScreenSlice';
import {dropInPricePlansScreenSlice} from '@/common/redux/screens/slices/dropInPricePlansScreenSlice';
import {dropInPricePlansDetailScreenSlice} from '@/common/redux/screens/slices/dropInPricePlansDetailScreenSlice';
import {dropInSpaceSettingsSlice} from '@/common/redux/screens/slices/dropInSpaceSettingsSlice';
import multiTenantContractPlanScreenSlice from '@/common/redux/screens/slices/multiTenantContractPlanScreenSlice';
import upsertMultiTenantContractSlice from '@/common/redux/screens/slices/upsertMultiTenantContractSlice';
import multiTenantContractDetailScreenSlice from '@/common/redux/screens/slices/multiTenantContractDetailScreenSlice';
import specifiedCommercialTransactionSettingSlice from '@/common/redux/screens/slices/specifiedCommercialTransactionSettingSlice';

export const screensClearActions: {[K in keyof ScreensState]: () => any} = {
  renewContract: renewContractSlice.actions.clear,
  contractDetail: contractDetailSlice.actions.clear,
  createContract: createContractSlice.actions.clear,
  customerDetail: customerDetailSlice.actions.clear,
  releaseContractPlan: releaseContractPlanSlice.actions.clear,
  contractPlanScreen: contractPlanScreenSlice.actions.clear,
  spaceReportScreen: spaceReportScreenSlice.actions.clear,
  dropInPlansScreen: dropInPlansScreenSlice.actions.clear,
  dropInPricePlansScreen: dropInPricePlansScreenSlice.actions.clear,
  dropInPricePlansDetailScreen: dropInPricePlansDetailScreenSlice.actions.clear,
  multiTenantContractPlanScreen: multiTenantContractPlanScreenSlice.actions.clear,
  multiTenantContractDetailScreen: multiTenantContractDetailScreenSlice.actions.clear,
  upsertMultiTenantContractSlice: upsertMultiTenantContractSlice.actions.clear,
  areasDetailScreen: () => {
    return () => {
      dropInSpaceSettingsSlice.actions.clear();
    };
  },
  workhubPassSettingScreen: () => {
    return () => {
      specifiedCommercialTransactionSettingSlice.actions.clear();
    };
  },
};
