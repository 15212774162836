import {WHColor} from '@/common/styles/whColor';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import icon from './icon.png';
import Badge from '@mui/material/Badge';

const Circle = styled.div`
  border-radius: 30px;
  width: 40px;
  height: 40px;
  background: ${WHColor.surface.brandPrimary};
  color: ${WHColor.text.neutralWhitePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const AlgoliaWatcherDispatch: {
  increment: () => any;
} = {
  // 存在確認漏れでクラッシュしないように。そもそも毎回確認するのめんどくさいし。
  increment: () => {},
};
const AlgoliaWatcher = React.memo(function AlgoliaWatcher() {
  const [count, setCount] = useState(0);
  AlgoliaWatcherDispatch.increment = () => setCount(c => ++c);

  return (
    <Badge color='secondary' badgeContent={count}>
      <Circle>
        <Icon src={icon} alt='algolia icon' />
      </Circle>
    </Badge>
  );
});

export default AlgoliaWatcher;
