// generated-by-gen
import {getCoreAxios} from '@/common/axios/getCoreAxios';
import type {AxiosResponse} from 'axios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import Logger from '@/common/logger/logger';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
const logger = Logger.create('getReservableSpaceSettingSubsetsApi');

export const getReservableSpaceSettingSubsetsApi = async (
  arg: {query: GetReservableSpaceSettingSubsetsQueryParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .get<GetReservableSpaceSettingSubsetsResponse>(
      `/organizations/reservations/settings/reservableSpaceSettingSubsets`,
      {
        params: arg.query,
        headers: {
          authorization: `bearer ${idToken}`,
        },
      }
    )
    .catch(axiosCatch(getStackTrace()));
};

export type GetReservableSpaceSettingSubsetsResponse = {
  id: string;
  userGroupIds?: string[];
  /**
   * 予約受付期間
   * どのくらい先まで予約できるかを持つ
   *
   * number*unitで期間を定義する
   */
  reservablePeriod?: {
    unit: 'day' | 'week' | 'month' | 'year';
    count: number;
    additionalConditions?: {
      unit: 'day' | 'week' | 'month' | 'year';
      count: number;
      userGroupId: string;
      id: string;
    }[];
  };
  reservableTimesEachDayOfWeek?: {
    [K in 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday']: {
      startTime?: string;
      endTime?: string;
      timeZone?: string;
    }[];
  };
  reservableDayOfWeeks?: ('Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday')[];
  ableToInviteExternal?: boolean;
  permitReservationDuplication?: boolean;
  reservableTimeUnitInMinutes?: number;
  reservable?: boolean;
  allowPastDays?: boolean;
  baseReservableTimes?: {
    startTime?: string;
    endTime?: string;
    timeZone?: string;
  }[];
  useUserGroup?: boolean;
  maxReservationTimeLengthInMinutes?: number;
  space: {
    id: string;
    code: string;
    type: 'Region' | 'Building' | 'Floor' | 'Area' | 'Workspot';
    capacity?: number;
    name?: string;
    nameJp: string;
    nameEn: string;
    parentId: string;
    parentIds: string[];
    parents?: {
      id: string;
      code: string;
      type: 'Region' | 'Building' | 'Floor' | 'Area';
      nameJp: string;
      nameEn: string;
    }[];
    iconImage?: string;
    availableUserGroupIds?: string[];
    ownerOrganizationId?: string;
    attributes: (
      | 'MeetingRoom'
      | 'Facility'
      | 'Parking'
      | 'Workspace'
      | 'Reception'
      | 'OpenSpace'
      | 'Other'
      | 'CommonArea'
      | 'ExclusiveArea'
      | 'StaffOnly'
      | 'WorkBooth'
      | 'PrivateRoom'
    )[];
    deleted?: boolean;
  };
}[];
export type GetReservableSpaceSettingSubsetsQueryParam = {
  spaceTypes?: ('Region' | 'Building' | 'Floor' | 'Area' | 'Workspot')[];
  spaceAttributes?: (
    | 'MeetingRoom'
    | 'Facility'
    | 'Parking'
    | 'Workspace'
    | 'Reception'
    | 'OpenSpace'
    | 'Other'
    | 'CommonArea'
    | 'ExclusiveArea'
    | 'StaffOnly'
    | 'WorkBooth'
  )[];
  regionIds?: string[];
  buildingIds?: string[];
  floorIds?: string[];
  limit?: number;
  offset?: number;
  order?: {
    column: 'nameJp' | 'nameEn' | 'code';
    order: 'asc' | 'desc';
  };
};
