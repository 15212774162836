import {catchStack} from '@/common/error/stacktrace';
import {
  ApiTypesAuthorityPatternGetNextResponse,
  ApiTypesAuthorityPatternGetResponse,
  ApiTypesAuthorityPatternPatchRequest,
  ApiTypesAuthorityPatternPatchResponse,
  ApiTypesAuthorityPatternPostRequest,
  ApiTypesAuthorityPatternPostResponse,
} from '@bitkey-service/workhub-types/lib/api/workhub-account-api-types/organizations/authority-pattern/apiTypesAuthorityPattern';
import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../common/firebase/functions/firebase-functions';
import {ApiAccount} from '../apiAccount';

const basePath = '/authority-pattern';

export class ApiAccountAuthorityPattern {
  private static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiAccount.get<Response>(FirebaseFunctions.makePath(basePath, path), config).catch(catchStack());
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(catchStack());
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(catchStack());
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiAccount.delete<Response>(FirebaseFunctions.makePath(basePath, path), config).catch(catchStack());

  public static getAll = () => ApiAccountAuthorityPattern.get<ApiTypesAuthorityPatternGetResponse>('/');
  public static add = (data: ApiTypesAuthorityPatternPostRequest) =>
    ApiAccountAuthorityPattern.post<ApiTypesAuthorityPatternPostResponse, ApiTypesAuthorityPatternPostRequest>(
      '/',
      data
    );
  public static update = (id: string, data: ApiTypesAuthorityPatternPatchRequest) =>
    ApiAccountAuthorityPattern.patch<ApiTypesAuthorityPatternPatchResponse, ApiTypesAuthorityPatternPatchRequest>(
      `/${id}`,
      data
    );
  public static deleteById = (id: string) => ApiAccountAuthorityPattern.delete<{id: string}>(`/${id}`);
  public static getNext = (id?: string) =>
    ApiAccountAuthorityPattern.get<ApiTypesAuthorityPatternGetNextResponse>(`/next?${id ? `id=${id},` : ''}`);
}
