import React, {useMemo} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WButton from '../button/WButton';
import {Locale} from '../../common/redux/state-types/localeStateType';
import useDict from '../../common/hooks/useDict';

export type WAlertProps = {
  open: boolean;
  onCancel: () => any;
  onOk?: () => any;
  running?: boolean; // これ違くない？ダイアログは即閉じして外側が処理中のインタラクションになるのでは。UX勘違いして使われそうだから消したほうがよさそう。とりあえずoptionalにして使わないようにします。
  title: string;
  description?: string;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  // 微妙に迷ったけどこの形が使いやすそうなので
  destructive?: boolean;
  disable?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
};

export const dictDef = {
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  delete: {
    default: {
      default: '削除',
      [Locale.en_US]: 'Delete',
    },
  },
};

const WAlert = React.memo<WAlertProps>(function WAlert({
  open,
  onOk,
  onCancel,
  title,
  description,
  okButtonLabel,
  cancelButtonLabel,
  running,
  disable,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  ...props
}) {
  const multiLinedDescription = useMemo(() => {
    return description && description.includes('\n')
      ? description.split('\n').map((line, key) => (
          <span key={key}>
            {line}
            <br />
          </span>
        ))
      : description;
  }, [description]);
  const dict = useDict(dictDef);
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (!disableBackdropClick) {
          onCancel();
          return;
        }
        // disableBackdropClickがtrue時だけ判定
        if (reason !== 'backdropClick') {
          onCancel();
          return;
        }
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      data-testid='alert-dialog'
    >
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{multiLinedDescription}</DialogContentText>
        </DialogContent>
      )}
      {props.destructive ? (
        <DialogActions>
          {onOk && (
            <WButton
              label={okButtonLabel ?? dict.delete}
              onClick={onOk}
              running={running}
              color={'error'}
              disabled={running || disable}
            />
          )}
          <WButton label={cancelButtonLabel ?? dict.cancel} variant={'text'} onClick={onCancel} disabled={running} />
        </DialogActions>
      ) : (
        <DialogActions>
          <WButton label={cancelButtonLabel ?? dict.cancel} variant={'text'} disabled={running} onClick={onCancel} />
          {onOk && (
            <WButton label={okButtonLabel ?? 'OK'} onClick={onOk} running={running} disabled={running || disable} />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
});

export default WAlert;
