import {V2DataAddressTypes} from '@bitkey-service/v2_core-types/lib/common/v2_commonTypes';
import Logger from '@/common/logger/logger';
import axios from 'axios';

const EMPTY_STRING = '-';
const logger = Logger.create('AddressUtil');

export type ZipCloudResType = {
  zipcode: string; // 郵便番号
  prefcode: string; // 都道府県コード
  address1: string; // 都道府県名
  address2: string; // 市区町村名
  address3: string; // 町域名
  kana1: string; // 都道府県名カナ
  kana2: string; // 市区町村名カナ
  kana3: string; // 町域名カナ
};

export class AddressUtil {
  /**
   * Address型を文字列にして返却
   * @param address
   */
  public static toString = (address?: V2DataAddressTypes): string => {
    if (!address) return EMPTY_STRING;
    return `${address.prefecture ?? ''}${address.city ?? ''}${address.addressLine1 ?? ''}${address.addressLine2 ?? ''}`;
  };

  /**
   * 郵便番号からhyphenとか文字列を消す
   * @param postalCode
   */
  public static replaceString = (postalCode?: string): string => {
    if (!postalCode) return EMPTY_STRING;
    return postalCode.replace(/[^0-9]/g, '');
  };

  /**
   * UI用のPostalCodeにする
   * @param postalCode
   */
  public static displayPostalCode = (postalCode?: string): string => {
    if (!postalCode) return EMPTY_STRING;
    const replace = AddressUtil.replaceString(postalCode);
    if (!replace) return EMPTY_STRING;
    return `〒${replace.slice(0, 3) + '-' + replace.slice(3, replace.length)}`;
  };

  /**
   * 郵便番号から住所検索
   * zipcloud API を使用
   */
  public static getAddressFromPostalCode = async (postalCode: string): Promise<ZipCloudResType | null> => {
    if (postalCode.length !== 7) return null;
    const res = await axios.get('https://zipcloud.ibsnet.co.jp/api/search', {params: {zipcode: postalCode}});
    if (res.status !== 200) {
      logger.error('zipcloud API Error', res.data.status, res.data.message);
      return null;
    }
    if (!res.data.results) {
      logger.error('zipcloud API Error', res.data.status, res.data.message);
      return null;
    }
    return res.data.results[0];
  };
}
