import dayjs from 'dayjs';
import {WPeopleBasicInfoType} from '../domain/people/peopleBasicInfoType';

export class NameUtils {
  public static makeFileName = (name: string) => {
    return dayjs().format('YYYYMMDD') + '_' + name;
  };

  public static makeFullUserName = (state?: Pick<WPeopleBasicInfoType, 'familyNameJp' | 'firstNameJp'>) => {
    if (!state) {
      return '-';
    }
    const fullUserName = (state?.familyNameJp ?? '') + ' ' + (state?.firstNameJp ?? '');
    return fullUserName.trim() || '-';
  };

  public static makeFullUserNameKana = (state?: Pick<WPeopleBasicInfoType, 'familyNameKana' | 'firstNameKana'>) => {
    if (!state) {
      return '-';
    }
    const fullUserNameKana = (state.familyNameKana ?? '') + ' ' + (state.firstNameKana ?? '');
    return fullUserNameKana.trim() || '-';
  };

  public static makeFullUserNameEn = (state?: Pick<WPeopleBasicInfoType, 'firstNameEn' | 'familyNameEn'>) => {
    if (!state) {
      return '-';
    }
    const fullUserNameEn = (state.firstNameEn ?? '') + ' ' + (state.familyNameEn ?? '');
    return fullUserNameEn.trim() || '-';
  };
}
