// noinspection JSUnusedGlobalSymbols

import {createSvgIcon} from '@mui/material/utils';

export const PRO = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M12.927 2.25c-2.239 0-4.037 1.817-4.037 4.079v3.67c.55.112 1.027.372 1.431.742.55.557.844 1.224.844 2.003v.333c.734.26 1.248.927 1.248 1.743v3.597c0 1.038-.808 1.854-1.835 1.854h-.99c.733 1.112 1.98 1.854 3.375 1.854 2.239 0 4.037-1.817 4.037-4.079V6.33c-.037-2.262-1.835-4.079-4.073-4.079M7.569 16.674c0-.445.33-.778.77-.778a.78.78 0 0 1 .771.778c0 .445-.33.779-.77.779a.756.756 0 0 1-.771-.779' />
      <path
        fillRule='evenodd'
        d='M10.541 13.745h-.146v-.964c0-.594-.22-1.075-.624-1.483s-.918-.594-1.468-.594c-.587 0-1.064.186-1.468.594s-.624.89-.624 1.483v.964h-.11c-.624 0-1.101.482-1.101 1.112v3.597c0 .63.477 1.113 1.1 1.113h4.441c.624 0 1.101-.483 1.101-1.113v-3.597a1.11 1.11 0 0 0-1.1-1.112M7.35 12.78c0-.297.073-.52.293-.705a.94.94 0 0 1 .697-.296c.294 0 .514.074.698.296a.95.95 0 0 1 .293.705v.964H7.35zM6.1 18.454v-3.597h4.477v3.597z'
        clipRule='evenodd'
      />
    </g>
  </svg>,
  'PRO'
);

export const GATE = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path
        fillRule='evenodd'
        d='M6.956 3h4.103c.452 0 .828.15 1.205.452.338.339.489.715.489 1.167v12.352c0 .452-.15.828-.49 1.167-.338.339-.715.49-1.166.49H9.892v-2.41c0-.829-.527-1.507-1.28-1.77v-.34c0-.79-.301-1.468-.866-2.033a2.84 2.84 0 0 0-2.033-.828c-.075 0-.14.01-.207.019-.066.01-.131.018-.207.018V4.658c0-.452.15-.829.49-1.167.3-.34.715-.49 1.167-.49m3.087 9.301c.112-.15.188-.301.188-.452V9.78a.6.6 0 0 0-.188-.415.62.62 0 0 0-.452-.188.62.62 0 0 0-.452.188.6.6 0 0 0-.188.414v2.071c0 .189.075.34.188.452.15.113.301.188.452.188a.62.62 0 0 0 .452-.188M20.131 3c.452 0 .828.15 1.167.49q.452.45.452 1.13v12.388c0 .452-.188.829-.49 1.168-.3.339-.715.49-1.166.49H15.99c-.451 0-.828-.189-1.167-.49-.338-.301-.489-.716-.489-1.168V4.657c0-.452.15-.829.49-1.167.338-.34.715-.49 1.166-.49zm-2.672 9.414a.595.595 0 0 0 .602-.602V9.74a.595.595 0 0 0-.602-.603.64.64 0 0 0-.49.264.64.64 0 0 0-.113.376v1.996c0 .15 0 .226.075.339a.6.6 0 0 0 .527.301'
        clipRule='evenodd'
      />
      <path d='M6.504 18.025a.79.79 0 1 1-1.581 0 .79.79 0 0 1 1.58 0' />
      <path
        fillRule='evenodd'
        d='M7.972 15.088h-.15v-.98c0-.602-.226-1.091-.64-1.506C6.766 12.189 6.24 12 5.675 12s-1.092.188-1.506.602c-.414.415-.64.904-.64 1.507v.979h-.15c-.64 0-1.13.49-1.13 1.13v3.652c0 .64.49 1.13 1.13 1.13h4.554c.64 0 1.13-.49 1.13-1.13v-3.652c.037-.64-.452-1.13-1.092-1.13M4.697 14.07c0-.301.075-.527.301-.715a.96.96 0 0 1 .715-.302c.301 0 .527.076.716.302a.96.96 0 0 1 .3.715v.98H4.698zm-1.28 5.8v-3.653H8.01v3.652z'
        clipRule='evenodd'
      />
    </g>
  </svg>,
  'GATE'
);

export const bitreader = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 1.875a4.12 4.12 0 0 0-4.125 4.14v11.97A4.12 4.12 0 0 0 12 22.125a4.12 4.12 0 0 0 4.125-4.14V6.015A4.12 4.12 0 0 0 12 1.875m-1.725 14.453a.93.93 0 0 1-.937-.94.93.93 0 0 1 .937-.941.93.93 0 0 1 .938.94.93.93 0 0 1-.938.941m0-3.387A.93.93 0 0 1 9.338 12a.93.93 0 0 1 .937-.941.93.93 0 0 1 .938.941.93.93 0 0 1-.938.941m.075-3.388a.93.93 0 0 1-.937-.94.93.93 0 0 1 .937-.942.93.93 0 0 1 .938.941.93.93 0 0 1-.938.941m3.3 6.775a.93.93 0 0 1-.937-.94.93.93 0 0 1 .937-.941.93.93 0 0 1 .938.94.93.93 0 0 1-.938.941m0-3.387a.93.93 0 0 1-.937-.941.93.93 0 0 1 .937-.941.93.93 0 0 1 .938.941.93.93 0 0 1-.938.941m.075-3.388a.93.93 0 0 1-.937-.94.93.93 0 0 1 .937-.942.93.93 0 0 1 .938.941.93.93 0 0 1-.938.941'
      clipRule='evenodd'
    />
  </svg>,
  'bitreader'
);

export const bitlink = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M12.04 6.75a5.45 5.45 0 0 1 3.245 1.065c.365.265.385.8.065 1.12-.265.265-.68.28-.99.06a3.96 3.96 0 0 0-2.32-.745 3.96 3.96 0 0 0-2.32.745.75.75 0 0 1-.99-.06c-.315-.32-.295-.855.065-1.12A5.45 5.45 0 0 1 12.04 6.75' />
      <path d='M12.04 3.75c2.05 0 3.93.73 5.4 1.945.335.275.355.79.045 1.105a.75.75 0 0 1-1.01.045 6.96 6.96 0 0 0-8.87 0 .75.75 0 0 1-1.01-.045.75.75 0 0 1 .045-1.105 8.45 8.45 0 0 1 5.4-1.945M7.875 10.875a4.875 4.875 0 1 0 0 9.75h8.25a4.875 4.875 0 1 0 0-9.75z' />
    </g>
  </svg>,
  'bitlink'
);

// export const bitreader = createSvgIcon(
//   <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
//     <g clipPath='url(#prefix__a)'>
//       <path
//         fill='currentColor'
//         d='M11.919 0A5.12 5.12 0 0 0 6.8 5.119V18.88a5.12 5.12 0 0 0 10.238 0V5.12C17.028 2.287 14.74 0 11.918 0m0 19.703a.84.84 0 0 1-.842-.842v-.821c0-.04.03-.08.08-.08.039 0 .079.03.079.08v.821c0 .386.307.694.693.694a.69.69 0 0 0 .693-.694.69.69 0 0 0-.693-.693.7.7 0 0 0-.347.09c-.01.01-.02.01-.04.01-.039 0-.079-.03-.079-.08 0-.03.02-.05.04-.07a.84.84 0 0 1 1.257.733.843.843 0 0 1-.841.852m-.08-.93v.366h-.118v-.367zm-.128-.12v-.118h.119v.119zm.495 0v.12h-.119v.366h-.119v-.614h.12v.119h.118z'
//       />
//     </g>
//     <defs>
//       <clipPath id='prefix__a'>
//         <path fill='#fff' d='M0 0h24v24H0z' />
//       </clipPath>
//     </defs>
//   </svg>,
//   'bitreader'
// );

// export const bitlink = createSvgIcon(
//   <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
//     <rect width={20} height={10.746} x={2} y={7} fill='currentColor' rx={5.373} />
//     <g fill='#fff'>
//       <path d='M12.757 12.053h-.256v-.272h-.256v1.295h.256v-.767h.256zM11.974 11.781h-.256v.256h.255zM11.973 12.309h-.256v.784h.256z' />
//       <path d='M12.15 10.694c-.305 0-.624.08-.88.24-.064.016-.096.08-.096.128 0 .096.08.16.16.16.032 0 .064 0 .08-.016.223-.128.48-.192.735-.192.8 0 1.456.656 1.456 1.455 0 .8-.656 1.455-1.456 1.455a1.46 1.46 0 0 1-1.455-1.455l-.016-1.727a.16.16 0 0 0-.16-.16c-.08 0-.16.08-.16.16v1.727c0 .992.8 1.791 1.791 1.791.992 0 1.791-.8 1.791-1.79a1.806 1.806 0 0 0-1.79-1.776' />
//     </g>
//   </svg>,
//   'bitlink'
// );

export const bitlockPRO = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g clipPath='url(#prefix__a)'>
      <g clipPath='url(#prefix__b)'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12.087 0a4.29 4.29 0 0 1 4.288 4.28v15.44A4.29 4.29 0 0 1 12.087 24 4.29 4.29 0 0 1 7.8 19.72V4.28A4.29 4.29 0 0 1 12.087 0m0 .532A3.754 3.754 0 0 0 8.34 4.27v15.447a3.754 3.754 0 0 0 4.13 3.715 3.75 3.75 0 0 0 3.365-3.715V4.27a3.754 3.754 0 0 0-3.748-3.74m-3.374 19.18v.013a3.38 3.38 0 0 0 3.375 3.362 3.38 3.38 0 0 0 3.373-3.362v-.012a3.373 3.373 0 1 0-6.748 0m3.375-3.755c1.48 0 2.766.867 3.374 2.119V4.28A3.38 3.38 0 0 0 12.087.913 3.38 3.38 0 0 0 8.713 4.28v13.796a3.76 3.76 0 0 1 3.375-2.12'
          clipRule='evenodd'
        />
      </g>
    </g>
    <defs>
      <clipPath id='prefix__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
      <clipPath id='prefix__b'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>,
  'bitlockPRO'
);

export const bitlockLITE = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g clipPath='url(#prefix__a)'>
      <g clipPath='url(#prefix__b)'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M11.947 0a5.15 5.15 0 0 1 5.148 5.147h.01v12.415a5.35 5.35 0 0 0-1.35-2.258h-.001a5.37 5.37 0 0 0-3.797-1.572 5.34 5.34 0 0 0-3.797 1.573 5.35 5.35 0 0 0-1.36 2.29V5.148C6.8 2.301 9.1 0 11.947 0M8.465 15.61a4.9 4.9 0 0 1 3.086-1.427 1.01 1.01 0 0 0-.6.922v7.994a1.005 1.005 0 0 0 .57.9c-2.519-.222-4.5-2.341-4.5-4.907a4.88 4.88 0 0 1 1.444-3.483m6.974 6.965A4.85 4.85 0 0 1 12.377 24a.97.97 0 0 0 .566-.903v-8.002a1 1 0 0 0-.6-.914 4.85 4.85 0 0 1 3.096 1.428 4.88 4.88 0 0 1 1.444 3.483 4.88 4.88 0 0 1-1.443 3.482m-3.888.924a.55.55 0 0 0 .396.161c.313 0 .562-.249.562-.561V15.1a.56.56 0 1 0-1.123-.006v8.004c0 .144.06.285.165.4'
          clipRule='evenodd'
        />
      </g>
    </g>
    <defs>
      <clipPath id='prefix__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
      <clipPath id='prefix__b'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>,
  'bitlockLITE'
);

export const bitlockGATE = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g clipPath='url(#prefix__a)'>
      <g clipPath='url(#prefix__b)'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M2.684 20.552h18.632c1.492 0 2.698-1.206 2.684-2.684V6.284A2.69 2.69 0 0 0 21.316 3.6H2.684A2.69 2.69 0 0 0 0 6.284v11.584a2.69 2.69 0 0 0 2.684 2.684M18.344 6.6a.215.215 0 1 0 0-.43.215.215 0 0 0 0 .43m-7.09 4.206c.23-.13.488-.201.746-.201.847 0 1.521.674 1.507 1.521a1.507 1.507 0 1 1-3.014 0v-1.464c0-.086.072-.143.143-.143.086 0 .144.071.144.143v1.464a1.234 1.234 0 1 0 1.234-1.234c-.215 0-.43.057-.617.158-.029.014-.043.014-.072.014a.144.144 0 0 1-.143-.143c0-.043.028-.087.072-.115m.401.732h.216v.215h-.216zm.646.215h.23v.215h-.23v.66h-.215v-1.09h.215zm-.43.215h-.216v.66h.216zm8.11-5.583a.215.215 0 1 1-.431 0 .215.215 0 0 1 .43 0m1.205.215a.215.215 0 1 0 0-.43.215.215 0 0 0 0 .43'
          clipRule='evenodd'
        />
      </g>
    </g>
    <defs>
      <clipPath id='prefix__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
      <clipPath id='prefix__b'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>,
  'bitlockGATE'
);

export const ElectronicKey = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M18.194 8.298H16.79V5.79A4.793 4.793 0 0 0 12 1a4.793 4.793 0 0 0-4.79 4.79v2.508H5.806c-.99 0-1.806.805-1.806 1.806v10.323c0 .991.815 1.806 1.806 1.806h12.388c.99 0 1.806-.815 1.806-1.806V10.105a1.81 1.81 0 0 0-1.806-1.807M8.759 5.79A3.247 3.247 0 0 1 12 2.548a3.247 3.247 0 0 1 3.241 3.242v2.508H8.76zm9.693 14.637a.256.256 0 0 1-.259.258H5.807a.256.256 0 0 1-.258-.258V10.105c0-.145.114-.259.258-.259h12.388c.144 0 .258.114.258.258z' />
      <path d='M14.364 14.615h-1.858l.464-3.478-3.334 4.78h1.858l-.464 3.478z' />
    </g>
  </svg>,
  'ElectronicKey'
);

export const Cardreader = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='m22.59 21.19-1.61-5.83c-.01-.06-.36-1.52-1.16-3.05-.31-.59-1.37-1.48-1.73-1.77l-1.12-.79a.4.4 0 0 0-.09-.05l-.78-.4V6.42c0-.96-.79-1.75-1.75-1.75h-7c-.96 0-1.75.79-1.75 1.75v10.46c0 .96.79 1.75 1.75 1.75h6.1c.07 0 .12-.02.19-.04.33.35.7.76.88 1.03.5.71.67 1.3.67 1.3l.28 1.47c.07.36.38.61.74.61.04 0 .08 0 .11-.01l5.66-.86c.21-.03.4-.15.52-.33s.15-.4.09-.61M14.6 6.42v7.65c-.63-.56-1.98-1.53-3-.86-.4.26-.65.65-.71 1.1-.13.98.66 1.94.82 2.12l.61.7H9.86V6.17h4.49c.14 0 .25.11.25.25M7.1 16.88V6.42c0-.14.11-.25.25-.25h1.01v10.96H7.35c-.13 0-.25-.11-.25-.25m9.71 4.52-.15-.81c-.02-.09-.23-.87-.9-1.83-.45-.64-1.5-1.7-1.58-1.78l-1.33-1.53c-.3-.35-.58-.74-.53-.88.02-.11.11-.13.21-.12.25 0 .72.3 1.24.8l1.11.99c.23.18.53.21.79.09.26-.13.42-.39.42-.68V11l1.09.75c.59.48 1.2 1.07 1.3 1.26.71 1.36 1.02 2.69 1.04 2.73l1.39 5.04zM4.93 4.27c-.21 0-.41-.09-.56-.25l-.95-1.07a.746.746 0 0 1 .06-1.06c.31-.27.79-.25 1.06.06l.95 1.07c.27.31.25.78-.06 1.06-.15.13-.33.19-.5.19M8.03 3.74c-.41 0-.75-.34-.75-.75V1.75c0-.41.34-.75.75-.75s.75.34.75.75v1.24c0 .41-.33.75-.75.75M3.99 7.28c-.05 0-.1 0-.15-.01L2.6 7.02a.757.757 0 0 1-.59-.88c.08-.4.48-.67.89-.59l1.23.25c.41.08.67.47.59.88-.07.36-.38.6-.73.6' />
    </g>
  </svg>,
  'Cardreader'
);

export const tablet = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M21.21 4H2.79A1.8 1.8 0 0 0 1 5.79v12.73a1.8 1.8 0 0 0 1.79 1.79h18.42A1.8 1.8 0 0 0 23 18.52V5.79A1.8 1.8 0 0 0 21.21 4m-1.403 14.776H2.791a.253.253 0 0 1-.256-.256V5.79c0-.143.112-.255.256-.255h17.016zm1.607-5.802a.82.82 0 0 1-.819-.819c0-.45.369-.818.819-.818s.819.368.819.818-.369.819-.819.819'
    />
  </svg>,
  'tablet'
);

export const bitface = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M21.21 4H2.79A1.8 1.8 0 0 0 1 5.79v12.73a1.8 1.8 0 0 0 1.79 1.79h18.42A1.8 1.8 0 0 0 23 18.52V5.79A1.8 1.8 0 0 0 21.21 4m-1.403 14.776H2.791a.253.253 0 0 1-.256-.256V5.79c0-.143.112-.255.256-.255h17.016zm1.607-5.802a.82.82 0 0 1-.819-.819c0-.45.369-.818.819-.818s.819.368.819.818-.369.819-.819.819' />
      <path d='M6.055 9.638a.507.507 0 0 1-.512-.511V7.039c0-.286.225-.512.512-.512h2.17c.286 0 .51.226.51.512a.507.507 0 0 1-.51.512H6.566v1.576a.507.507 0 0 1-.512.511M16.287 9.638a.507.507 0 0 1-.511-.511V7.55h-1.658a.507.507 0 0 1-.511-.512c0-.286.225-.512.511-.512h2.17c.286 0 .511.226.511.512v2.088a.513.513 0 0 1-.512.511M8.214 17.783h-2.16a.507.507 0 0 1-.51-.511v-2.088c0-.286.224-.511.51-.511s.513.225.513.511v1.576h1.657c.287 0 .512.225.512.512a.515.515 0 0 1-.522.511M16.287 17.783h-2.169a.507.507 0 0 1-.511-.511c0-.287.225-.512.511-.512h1.658v-1.576c0-.286.225-.511.511-.511s.512.225.512.511v2.088a.513.513 0 0 1-.512.511M11.171 8.37a3.79 3.79 0 0 0-3.786 3.785 3.79 3.79 0 0 0 3.786 3.787 3.79 3.79 0 0 0 3.786-3.787 3.79 3.79 0 0 0-3.786-3.786m0 6.548a2.764 2.764 0 0 1-2.763-2.763c0-.266.052-.521.123-.777.4.092 1.013.123 1.801-.174.91-.348 1.207-1.32 1.3-1.77a2.76 2.76 0 0 1 2.292 2.711 2.75 2.75 0 0 1-2.753 2.773' />
      <path d='M10.168 12.902a.512.512 0 1 0 0-1.023.512.512 0 0 0 0 1.023M12.287 12.902a.512.512 0 1 0 0-1.023.512.512 0 0 0 0 1.023' />
    </g>
  </svg>,
  'bitface'
);

export const Roomsupport = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M21.21 4H2.79A1.8 1.8 0 0 0 1 5.79v12.73a1.8 1.8 0 0 0 1.79 1.79h18.42A1.8 1.8 0 0 0 23 18.52V5.79A1.8 1.8 0 0 0 21.21 4m-1.403 14.776H2.791a.253.253 0 0 1-.256-.256V5.79c0-.143.112-.255.256-.255h17.016zm1.607-5.802a.82.82 0 0 1-.819-.819c0-.45.369-.818.819-.818s.819.368.819.818-.369.819-.819.819' />
      <path d='M16.247 11.214c-.185-2.66-2.374-4.768-5.086-4.768S6.27 8.553 6.086 11.214a1.6 1.6 0 0 0-.031.317v1.883c0 .819.665 1.494 1.494 1.494h.245c.819 0 1.494-.665 1.494-1.494V11.52c0-.819-.665-1.494-1.494-1.494H7.55c-.062 0-.113.01-.174.02A4.1 4.1 0 0 1 11.17 7.46a4.075 4.075 0 0 1 3.796 2.588c-.061-.01-.112-.02-.174-.02h-.245c-.819 0-1.494.665-1.494 1.494v1.893c0 .819.665 1.494 1.494 1.494h.245c.093 0 .174-.01.256-.03-.122.419-.296.797-.573.971-.46.297-1.391.369-1.954.379a.94.94 0 0 0-.809-.491H10.64a.94.94 0 0 0-.941.941v.143c0 .512.42.942.941.942h1.075a.92.92 0 0 0 .808-.491c.593-.01 1.79-.082 2.507-.532 1.156-.737 1.258-2.733 1.269-3.295V11.52c-.01-.102-.02-.205-.051-.307m-8.698-.154h.245a.47.47 0 0 1 .471.471v1.883a.47.47 0 0 1-.47.47h-.246a.476.476 0 0 1-.47-.47v-1.862c0-.02.01-.041.01-.062a.453.453 0 0 1 .46-.43m4.328 5.751a.18.18 0 0 1-.174.174H10.64a.18.18 0 0 1-.174-.174v-.143a.18.18 0 0 1 .174-.174h1.075a.18.18 0 0 1 .173.174v.143zm3.387-3.51v.123a.47.47 0 0 1-.46.46h-.246a.476.476 0 0 1-.47-.47V11.52a.47.47 0 0 1 .47-.47h.246c.245 0 .45.194.46.44 0 .02.01.04.01.06v1.75z' />
    </g>
  </svg>,
  'Roomsupport'
);

export const bitreception = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M21.21 4H2.79A1.8 1.8 0 0 0 1 5.79v12.73a1.8 1.8 0 0 0 1.79 1.79h18.42A1.8 1.8 0 0 0 23 18.52V5.79A1.8 1.8 0 0 0 21.21 4m-1.403 14.776H2.791a.253.253 0 0 1-.256-.256V5.79c0-.143.112-.255.256-.255h17.016zm1.607-5.802a.82.82 0 0 1-.819-.819c0-.45.369-.818.819-.818s.819.368.819.818-.369.819-.819.819' />
      <path d='M15.807 9.802c-.185-2.098-1.924-3.755-4.073-3.755a4.12 4.12 0 0 0-4.114 4.01c.952-.05 2.241-.49 2.773-.92.655-.532.89-1.535 1.013-2.098.144.583.84 1.699 1.433 2.026.9.502 2.292.727 2.967.737M13.146 13.567l-1.535.89-1.535-.89v2.262l1.535-.89 1.535.89zM8.183 13.25s-1.166.143-1.76.727c-.583.583-.634 1.964-.634 1.964v2.477h4.185zM15.06 13.25s1.166.143 1.76.727c.583.583.634 1.964.634 1.964v2.477h-4.185z' />
    </g>
  </svg>,
  'bitreception'
);

export const EdgeWorkstation = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      d='M22.292 10.914h-3.725c-.038 0-.067.019-.095.019V8.788c0-.593-.316-1.148-.843-1.455l-1.742-1.005c.01-.01.019-.01.019-.02l1.867-3.225a.715.715 0 0 0-1.235-.718L14.67 5.59c-.01.01-.01.019-.01.029l-1.809-1.044a1.69 1.69 0 0 0-1.675 0L9.357 5.62c-.009-.01 0-.02-.009-.03L7.481 2.366a.71.71 0 0 0-.976-.269.73.73 0 0 0-.268.987l1.867 3.226c.01.01.019.01.019.019L6.38 7.343a1.68 1.68 0 0 0-.842 1.445v2.135c-.029 0-.067-.019-.096-.019H1.718a.73.73 0 0 0-.718.728c0 .402.325.718.718.718h3.724c.038 0 .067-.02.096-.02v1.992c0 .594.325 1.149.842 1.455L8.21 16.83a.7.7 0 0 0-.105.125L6.237 20.18a.715.715 0 0 0 .612 1.072.7.7 0 0 0 .623-.364l1.867-3.226c.028-.048.038-.105.047-.153l1.772 1.024c.258.153.545.22.842.22s.574-.076.842-.22l1.772-1.024c.019.048.019.105.047.153l1.867 3.226c.134.23.374.364.623.364a.66.66 0 0 0 .354-.096.71.71 0 0 0 .258-.976l-1.867-3.226c-.028-.048-.067-.086-.105-.125l1.829-1.053c.517-.297.842-.852.842-1.455V12.33c.029 0 .058.019.096.019h3.724a.723.723 0 0 0 .718-.718.715.715 0 0 0-.708-.718m-5.266 3.398a.26.26 0 0 1-.115.21l-4.787 2.768a.26.26 0 0 1-.239 0l-4.787-2.767c-.076-.038-.115-.124-.115-.21V8.787c0-.086.048-.162.115-.21l4.787-2.767A.26.26 0 0 1 12 5.782c.038 0 .086.01.115.029l4.787 2.767a.23.23 0 0 1 .124.21z'
    />
    <path
      fill='currentColor'
      d='M13.498 14.266c-.085 0-.16-.04-.182-.095l-1.142-2.422a.19.19 0 0 0-.173-.107.2.2 0 0 0-.172.107l-1.133 2.422c-.022.049-.085.095-.17.095h-.053c-.084 0-.16-.043-.181-.104l-1.268-3.918q-.046-.11-.003-.171c.028-.043.084-.067.153-.067h.576c.072 0 .172.052.191.125l.573 1.844a.19.19 0 0 0 .166.132h.013c.072 0 .14-.043.172-.107l.94-1.994c.021-.055.1-.104.18-.104h.063c.085 0 .157.04.182.104l.927 1.997a.19.19 0 0 0 .172.107h.012a.19.19 0 0 0 .17-.132l.563-1.847c.044-.1.157-.125.329-.125h.416c.072 0 .132.024.16.07s.028.107 0 .168l-1.246 3.918c-.022.06-.094.104-.191.104z'
    />
  </svg>,
  'EdgeWorkstation'
);

export const bitlockPRO2 = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <g fill='currentColor'>
      <path d='M16.48 20.274A4.81 4.81 0 0 1 11.8 24a4.805 4.805 0 0 1-4.68-3.726 5.11 5.11 0 0 0 4.626 2.946h.108a5.11 5.11 0 0 0 4.626-2.946' />
      <path d='M16.6 4.8v13.314a4.753 4.753 0 0 1-4.746 4.746h-.108A4.753 4.753 0 0 1 7 18.114V4.8C7 2.148 9.148 0 11.8 0c1.326 0 2.526.54 3.396 1.404.87.87 1.404 2.07 1.404 3.396' />
    </g>
  </svg>,
  'bitlockPRO2'
);

export const bitlockreader = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11.815 0a4.875 4.875 0 0 1 4.828 4.875v14.25A4.875 4.875 0 0 1 11.815 24 4.865 4.865 0 0 1 7 19.14V4.86A4.865 4.865 0 0 1 11.815 0m.73 12.387c.06 0 .113-.053.113-.114v-.6a.27.27 0 0 0-.265-.265h-1.071a.27.27 0 0 0-.266.265v.646a.27.27 0 0 0 .266.266h.828c.06 0 .113-.053.113-.114a.117.117 0 0 0-.114-.114h-.82q-.036-.002-.038-.038v-.311h.76c.06 0 .114-.054.114-.114a.117.117 0 0 0-.114-.114h-.767v-.107c0-.022.015-.038.038-.038h1.07c.023 0 .039.016.039.038v.6c0 .061.053.114.113.114'
      clipRule='evenodd'
    />
  </svg>,
  'bitlockreader'
);

// export const tablet = createSvgIcon(
//   <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
//     <g fill='currentColor'>
//       <path
//         fillRule='evenodd'
//         d='M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm4 12h14V6H6zm-3-6a1 1 0 1 1 2 0 1 1 0 0 1-2 0'
//         clipRule='evenodd'
//       />
//       <path
//         fillRule='evenodd'
//         d='M9.75 8.5H11A.75.75 0 0 0 11 7H9.2a.95.95 0 0 0-.95.95v1.8a.75.75 0 0 0 1.5 0zM16.25 14.5H15a.75.75 0 0 0 0 1.5h1.8a.95.95 0 0 0 .95-.95v-1.8a.75.75 0 0 0-1.5 0zM16.5 8.5v1.25a.75.75 0 0 0 1.5 0v-1.8a.95.95 0 0 0-.95-.95h-1.8a.75.75 0 0 0 0 1.5zM9.75 14.5v-1.25a.75.75 0 0 0-1.5 0v1.8c0 .525.425.95.95.95H11a.75.75 0 0 0 0-1.5z'
//         clipRule='evenodd'
//       />
//       <path d='M16 11.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0' />
//       <path
//         fillRule='evenodd'
//         d='M18.987 20H7.013c.206-2.234 2.808-3.5 5.987-3.5s5.781 1.266 5.987 3.5'
//         clipRule='evenodd'
//       />
//     </g>
//   </svg>,
//   'tablet'
// );
