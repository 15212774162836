export default class ArrayUtil {
  /**
   * 複数の配列を、重複のないひとつの配列にまとめる
   * @param arrays
   */
  public static reduceArrays = <T extends number | string>(arrays: T[][]): T[] => {
    const pushed: T[] = [];
    for (const arr of arrays) {
      Array.prototype.push.apply(pushed, arr);
    }
    return pushed.filter((val, idx, self) => self.indexOf(val) === idx);
  };

  /**
   * 重複を削除する
   * @param arr
   */
  public static removeDuplicateBySet = <T extends number | string>(arr: T[]): T[] => {
    return Array.from(new Set(arr));
  };

  /**
   * idをキーにして重複を除去するメソッド
   * @param param
   */
  public static removeDuplicateById = <T extends {id: string}>(param: (T | undefined)[]): T[] =>
    param.filter((x, i, self) => x !== undefined && self.findIndex(y => y !== undefined && y.id === x.id) === i) as T[];

  /**
   * キーを指定して重複を除去するメソッド
   * @param param
   */
  public static removeDuplicateByKey = <T extends object>(param: (T | undefined)[], key: keyof T): T[] =>
    param.filter(
      (x, i, self) => x !== undefined && self.findIndex(y => y !== undefined && y[key] === x[key]) === i
    ) as T[];
  /**
   * 分割された電話番号をハイフンで区切る
   */
  public static formatPhoneWithHyphen = (splitPhoneNumber: [string, string, string]): string => {
    return splitPhoneNumber.filter(number => !!number && number !== '').join('-');
  };
  /**
   * 左詰にする (null, undefined, "" が右詰になる)
   * 例) ['1', '', '3', '4', '', '6'] => ['1', '3', '4', '6', '', '']
   * ※ 0 は右詰されない
   */
  public static justifyLeft = (array: any[]): any[] => {
    const squeezedArray = [...array];
    let i = 0;
    let j = 0;
    while (j < squeezedArray.length) {
      if (squeezedArray[j] || typeof squeezedArray[j] === 'number') {
        [squeezedArray[i], squeezedArray[j]] = [squeezedArray[j], squeezedArray[i]];
        i++;
      }
      j++;
    }
    return squeezedArray;
  };

  public static groupBy = <K, V>(
    array: readonly V[],
    getKey: (cur: V, idx: number, src: readonly V[]) => K
  ): [K, V[]][] =>
    Array.from(
      array.reduce((map, cur, idx, src) => {
        const key = getKey(cur, idx, src);
        const list = map.get(key);

        if (list) {
          list.push(cur);
        } else {
          map.set(key, [cur]);
        }

        return map;
      }, new Map<K, V[]>())
    );

  /**
   * 配列を指定数の要素ごとに分割する
   * @param params
   * @param num
   */
  public static divide = <T>(params: T[], num: number): T[][] => {
    const result: T[][] = [];
    for (let i = 0; i * num < params.length; i++) {
      result.push(params.slice(i * num, (i + 1) * num));
    }
    return result;
  };

  /**
   * 配列からundefined/null/空文字/0など(JSでfalseと判定されるもの)を除去する
   * @param array
   */
  public static removeEmpty = <T>(array: (T | undefined | null)[]): T[] => {
    return array.filter((element): element is T => !!element);
  };
  // 上記、書いてくれてはいるけどdoc見ない人が意図しない0排除等やらかしそうだからremoveFalsyとかにしたほうがいいのでは
  // undefinedを消して型判定でラクしたいユースケースがほとんどだと思うので名前変えて以下で定義する
  public static removeUndefined = <T>(arr: (T | undefined)[]): T[] => arr.filter(a => a !== undefined) as T[];

  /**
   * 特定のプロパティの値をkeyにしたobjectを返す
   */
  public static keyBy = <T>(array: T[], mapKey: (elem: T) => string): {[key: string]: T} => {
    return array.reduce((prev, elem) => {
      const key = mapKey(elem);
      prev[key] = elem;
      return prev;
    }, {});
  };

  /**
   * 配列の一致をチェックする関数
   * 一旦プリミティブ型の配列のみ対応
   * 基本的に順番を気にしない比較。考慮したい場合considerOrderをtrueにする
   * @param a
   * @param b
   */
  public static isSame = <T extends string | number | boolean>(a?: T[], b?: T[], considerOrder?: boolean): boolean => {
    if (a === undefined && b === undefined) {
      return true;
    }
    if (a === undefined || b === undefined) {
      return false;
    }

    if (considerOrder) {
      return a.every((v, i) => v === b[i]);
    } else {
      if (a.some(c => !b.includes(c))) {
        return false;
      }
      if (b.some(c => !a.includes(c))) {
        return false;
      }
      return true;
    }
  };

  public static union = <T extends string | number | boolean>(a: T[], b: T[]): T[] => {
    return Array.from(new Set([...a, ...b]));
  };
  public static difference = <T extends string | number | boolean>(a: T[], b: T[]): T[] => {
    return a.filter(x => !b.includes(x));
  };
}
