// generated by gen/fonts
const core = {
  fontFamilies: {notoSansJp: "Roboto, 'Noto Sans JP', sans-serif"},
  lineHeights: {none: '100%', low: '125%', normal: '150%', high: '175%'},
  fontWeights: {regular: 400, medium: 500},
  fontSize: {xxs: 11, xs: 12, s: 14, m: 16, l: 20, xl: 24, xl2: 28, xl3: 32, xl4: 36, xl5: 44, xl6: 56},
  letterSpacing: {none: 0, xs: 0.1, s: 0.15, m: 0.25, l: 0.4, xl: 0.5},
  textDecoration: {none: 'none', underline: 'underline'},
};

export const WHFont = {
  overline: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xxs,
    letterSpacing: core.letterSpacing.xl,
    textDecoration: core.textDecoration.none,
  },
  displayLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl6,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  displayMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl5,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  displaySmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl4,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  headlineLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl3,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  headlineMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl2,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  headlineSmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.xl,
    letterSpacing: core.letterSpacing.none,
    textDecoration: core.textDecoration.none,
  },
  titleLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.l,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.none,
  },
  titleMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.high,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.none,
  },
  titleSmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.xs,
    textDecoration: core.textDecoration.none,
  },
  labelLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  labelLargeStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  labelMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.m,
    textDecoration: core.textDecoration.none,
  },
  labelMediumStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  labelSmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  labelSmallStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  bodyLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  bodyLargeStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.none,
  },
  bodyMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.m,
    textDecoration: core.textDecoration.none,
  },
  bodyMediumStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.m,
    textDecoration: core.textDecoration.none,
  },
  bodySmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.none,
  },
  bodySmallStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.none,
  },
  linkLarge: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.underline,
  },
  linkLargeStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.m,
    letterSpacing: core.letterSpacing.l,
    textDecoration: core.textDecoration.underline,
  },
  linkMedium: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.m,
    textDecoration: core.textDecoration.underline,
  },
  linkMediumStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.s,
    letterSpacing: core.letterSpacing.m,
    textDecoration: core.textDecoration.underline,
  },
  linkSmall: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.regular,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.underline,
  },
  linkSmallStrong: {
    fontFamily: core.fontFamilies.notoSansJp,
    fontWeight: core.fontWeights.medium,
    lineHeight: core.lineHeights.normal,
    fontSize: core.fontSize.xs,
    letterSpacing: core.letterSpacing.s,
    textDecoration: core.textDecoration.underline,
  },
};

export const WHFontCss = {
  overline: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xxs}px;
letter-spacing: ${core.letterSpacing.xl}px;
text-decoration: ${core.textDecoration.none};
`,
  displayLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl6}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  displayMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl5}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  displaySmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl4}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  headlineLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl3}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  headlineMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl2}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  headlineSmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.xl}px;
letter-spacing: ${core.letterSpacing.none}px;
text-decoration: ${core.textDecoration.none};
`,
  titleLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.l}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.none};
`,
  titleMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.high};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.none};
`,
  titleSmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.xs}px;
text-decoration: ${core.textDecoration.none};
`,
  labelLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  labelLargeStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  labelMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.m}px;
text-decoration: ${core.textDecoration.none};
`,
  labelMediumStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  labelSmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  labelSmallStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  bodyLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  bodyLargeStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.none};
`,
  bodyMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.m}px;
text-decoration: ${core.textDecoration.none};
`,
  bodyMediumStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.m}px;
text-decoration: ${core.textDecoration.none};
`,
  bodySmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.none};
`,
  bodySmallStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.none};
`,
  linkLarge: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.underline};
`,
  linkLargeStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.m}px;
letter-spacing: ${core.letterSpacing.l}px;
text-decoration: ${core.textDecoration.underline};
`,
  linkMedium: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.m}px;
text-decoration: ${core.textDecoration.underline};
`,
  linkMediumStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.s}px;
letter-spacing: ${core.letterSpacing.m}px;
text-decoration: ${core.textDecoration.underline};
`,
  linkSmall: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.regular};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.underline};
`,
  linkSmallStrong: `
font-family: ${core.fontFamilies.notoSansJp};
font-weight: ${core.fontWeights.medium};
line-height: ${core.lineHeights.normal};
font-size: ${core.fontSize.xs}px;
letter-spacing: ${core.letterSpacing.s}px;
text-decoration: ${core.textDecoration.underline};
`,
};
