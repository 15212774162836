import axios from 'axios';

/**
 * cloud runのcore APIクライアントとなるaxiosインスタンスを取得する
 * IDトークンは利用するところで取得して渡す
 */
export const getCoreAxios = (opts?: {region?: 'default' | 'asia'}) => {
  return axios.create({
    baseURL: getBaseUrl(opts?.region),
    headers: {
      'content-type': 'application/json',
    },
  });
};
const getBaseUrl = (region?: 'default' | 'asia') => {
  if (import.meta.env.VITE_LOCAL_API === 'core') {
    return import.meta.env.VITE_CORE_LOCAL_API_URL;
  }
  if (region === 'asia') {
    return import.meta.env.VITE_CORE_ASIA_API_URL;
  }
  return import.meta.env.VITE_CORE_DEFAULT_API_URL;
};
