import {contextContainer} from '@/common/hooks/contextContainer';
import {useState} from 'react';
import {SimpleSnackbarState, SimpleSnackbarType} from '../snackbar/SimpleSnackbar';

const snackbarContextInitialValue: SimpleSnackbarState = {open: false, message: '', type: SimpleSnackbarType.Success};
export const useSimpleSnackbarState = () => {
  const [state, setState] = useState<SimpleSnackbarState>(snackbarContextInitialValue);
  return {state, setState};
};

export const simpleSnackbarContainer = contextContainer(useSimpleSnackbarState);
