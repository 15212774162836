import {catchStack} from '@/common/error/stacktrace';
import {
  ApiTypesOrganizationNameGetByKeyIdReq,
  ApiTypesOrganizationNameGetByKeyIdRes,
  ApiTypesOrganizationNameGetReq,
  ApiTypesOrganizationNameGetRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/apiTypesOrganizations';
import {V2StoreTypesOrganization} from '@bitkey-service/v2_core-types/lib/store/organizations/v2_storeTypesOrganization';
import {V2UpdateData} from '@bitkey-service/workhub-functions-common-types/lib/firestore/v2_coreDataTypes';
import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../common/firebase/functions/firebase-functions';
import {ApiWorkhubCore} from '../apiWorkhubCore';

const basePath = 'organizations';
export class ApiCoreOrg {
  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(
      catchStack()
    );
  public static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.get<Response>(FirebaseFunctions.makePath(basePath, path), config).catch(catchStack());
  public static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(
      catchStack()
    );
  public static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.delete<Response>(FirebaseFunctions.makePath(basePath, path), config).catch(catchStack());

  public static update = (data: V2UpdateData<V2StoreTypesOrganization>) =>
    ApiCoreOrg.patch<V2StoreTypesOrganization, V2UpdateData<V2StoreTypesOrganization>>('/', data);

  public static getOrganizationNames = (
    data: ApiTypesOrganizationNameGetReq
  ): Promise<ApiTypesOrganizationNameGetRes> => ApiCoreOrg.post('/get-names', data);

  public static getOrganizationNameByKeyIds = (
    data: ApiTypesOrganizationNameGetByKeyIdReq
  ): Promise<ApiTypesOrganizationNameGetByKeyIdRes> => ApiCoreOrg.post('/get-name-by-keyids', data);

  public static getOrganizationByCode = (data: {code: string}): Promise<{id: string; name: string}> =>
    ApiCoreOrg.post('/get-by-code', data);
}
