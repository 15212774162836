import styled from '@emotion/styled';
import Link from '@mui/material/Link';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';
import {ActivationGroup} from '../../common/feature-control/featureDefinitions';
import {useLoginUser} from '../../common/hooks/useLoginUser';
import {useSnackbar} from '../../common/hooks/useSnackbar';
import Logger from '../../common/logger/logger';
import {AlgoliaState} from '../../common/redux/state-types/algoliaState';
import {RootState} from '../../common/redux/store/rootStateType';
import WAlert from '../../components/alert/WAlert';
import {DebuggerService, activationGroupBlackListMax} from './debuggerService';
import ActivationsDeleteDialog from './dialog/ActivationsDeleteDialog';
import ActivationsAdditionDialog from './dialog/ActivationsAdditionDialog';
import FeatureCsvDownloadDialog from './dialog/FeatureCsvDownloadDialog';
import FeatureGroupCsvDownloadDialog from './dialog/FeatureGroupCsvDownloadDialog';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import {WHColor} from '@/common/styles/whColor';
import {getCurrentVersion} from '@/common/utils/version';
import {UserState} from '@/common/redux/state-types/userStateType';
import {simpleSnackbarContainer} from '../layouts/simpleSnackbarContainer';
import SimpleSnackbar from '../snackbar/SimpleSnackbar';

const logger = Logger.create('Debugger');

const Content = styled.div`
  margin-top: 8px;
`;

const Version = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px;
`;

const useStyles = makeStyles()({
  extendedIcon: {
    marginRight: 16,
    color: WHColor.text.neutralWhitePrimary,
  },
  white: {
    color: WHColor.text.neutralWhitePrimary,
  },
  button: {
    fontSize: 12,
  },
});

async function copyIdToken() {
  const idToken = await getFirebaseIdToken();
  if (!idToken) {
    throw new Error('idToken is empty');
  }
  await navigator.clipboard.writeText(idToken);
}

async function copyLoginUserInfo(user: UserState) {
  const userInfo = `
  ログイン情報: ${user.email}
  組織: ${user.organization?.name} / ${user.organizationId}
  画面: ${window.location.pathname}`;
  await navigator.clipboard.writeText(userInfo);
}

function Debugger() {
  const user = useLoginUser();
  const algolia = useSelector<RootState, AlgoliaState>(s => s.algolia);
  const snackbar = useSnackbar();
  const {classes} = useStyles();
  const [processing, setProcessing] = useState<boolean>(false);
  const snackbarState = simpleSnackbarContainer.useValue();

  const organizationInfoUrl = useMemo(() => {
    const projectHost = import.meta.env.VITE_FIREBASE_PROJECT_ID as string;
    return `https://console.firebase.google.com/u/0/project/${projectHost}/firestore/data/~2Forganizations~2F${user.organizationId}`;
  }, [user.organizationId]);

  const algoliaInfoUrl = useMemo(() => {
    if (!algolia.organizationId) {
      return null;
    }
    return `https://www.algolia.com/apps/${algolia.applicationId}/explorer/browse/${algolia.organizationId}_things`;
  }, [algolia.applicationId, algolia.organizationId]);

  const onSuccess = useCallback(() => {
    snackbar.success('ダウンロード成功');
  }, [snackbar]);
  const onFail = useCallback(() => {
    snackbar.fail('エラー');
  }, [snackbar]);

  const [downloadFeatureOpen, setDownloadFeatureOpen] = useState(false);
  const toggleDownloadFeatureOpen = useCallback((shouldOpen: boolean) => {
    setDownloadFeatureOpen(shouldOpen);
  }, []);

  const [downloadFeatureGroupOpen, setDownloadFeatureGroupOpen] = useState(false);
  const toggleDownloadFeatureGroupOpen = useCallback((shouldOpen: boolean) => {
    setDownloadFeatureGroupOpen(shouldOpen);
  }, []);

  const [addActivationsOpen, setAddActivationsOpen] = useState(false);
  const toggleAddActivationsOpen = useCallback((shouldOpen: boolean) => {
    setAddActivationsOpen(shouldOpen);
  }, []);

  const addActivationsMax = useCallback(async () => {
    setProcessing(true);
    try {
      const activationGroups = Object.values(ActivationGroup).filter(
        activationGroup => !activationGroupBlackListMax.includes(activationGroup)
      );
      await DebuggerService.addActivations(activationGroups);
      snackbar.success('表示メニューの全追加が完了しました');
    } catch (e) {
      logger.error('failed to restore.', e);
      snackbar.fail('表示メニューの全追加に失敗しました');
    } finally {
      setProcessing(false);
      setAddActivationsOpen(false);
    }
  }, [snackbar]);

  const [addSelectedActivationsOpen, setAddSelectedActivationsOpen] = useState(false);
  const toggleAddSelectedActivationsOpen = useCallback((shouldOpen: boolean) => {
    setAddSelectedActivationsOpen(shouldOpen);
  }, []);

  const [deleteSelectedActivationsOpen, setDeleteSelectedActivationsOpen] = useState<boolean>(false);
  const toggleDeleteSelectedActivationsOpen = useCallback((shouldOpen: boolean) => {
    setDeleteSelectedActivationsOpen(shouldOpen);
  }, []);

  const onClickIdToken = useCallback(async () => {
    try {
      await copyIdToken();
      snackbar.success('IDトークンをコピーしました');
    } catch {
      snackbar.fail('IDトークンのコピーに失敗しました');
    }
  }, [snackbar]);

  const onClickCopyLoginUserInfo = useCallback(async () => {
    try {
      await copyLoginUserInfo(user);
      snackbar.success('デバッグ情報をコピーしました');
    } catch {
      snackbar.fail('デバッグ情報のコピーに失敗しました');
    }
  }, [snackbar, user]);
  const version = getCurrentVersion();

  return (
    <>
      <Content>
        <div>組織ID</div>
        <div>{user.organizationId}</div>
        <Link href={organizationInfoUrl} target='_blank'>
          組織情報
        </Link>
      </Content>
      <Content>
        <div>デバッグ情報</div>
        <Link className={classes.button} onClick={onClickCopyLoginUserInfo} component={'button'}>
          コピー
        </Link>
      </Content>
      <Content>
        <div>Algolia</div>
        <div>{algolia.applicationId}</div>
        {algoliaInfoUrl && (
          <Link href={algoliaInfoUrl} target='_blank'>
            コンソール
          </Link>
        )}
      </Content>
      <Content>
        <div>メニューの紐付き資料ダウンロード</div>
        <Link className={classes.button} onClick={() => toggleDownloadFeatureGroupOpen(true)} component={'button'}>
          メニュー表示（activation）資料ダウンロード
        </Link>
        <br />
        <Link className={classes.button} onClick={() => toggleDownloadFeatureOpen(true)} component={'button'}>
          権限資料ダウンロード
        </Link>
      </Content>
      <Content>
        <div>メニュー表示</div>
        <Link className={classes.button} onClick={() => toggleAddSelectedActivationsOpen(true)} component={'button'}>
          表示メニュー（activation）追加
        </Link>
        <br />
        <Link className={classes.button} onClick={() => toggleAddActivationsOpen(true)} component={'button'}>
          表示メニュー（activation）全追加
        </Link>
        <br />
        <Link className={classes.button} onClick={() => toggleDeleteSelectedActivationsOpen(true)} component={'button'}>
          表示メニュー（activation）削除
        </Link>
      </Content>
      <Content>
        <div>IDトークン</div>
        <Link className={classes.button} onClick={onClickIdToken} component={'button'}>
          コピー
        </Link>
      </Content>
      <WAlert
        open={addActivationsOpen}
        title={'表示メニュー全追加'}
        description={'表示メニューを全追加します。\n\nなお、お客様へ提供済みの機能だけ開放します。'}
        onCancel={() => toggleAddActivationsOpen(false)}
        onOk={addActivationsMax}
        running={processing}
      />
      <FeatureGroupCsvDownloadDialog
        open={downloadFeatureGroupOpen}
        onClose={() => toggleDownloadFeatureGroupOpen(false)}
        onSuccess={onSuccess}
        onFail={onFail}
      />
      <FeatureCsvDownloadDialog
        open={downloadFeatureOpen}
        onClose={() => toggleDownloadFeatureOpen(false)}
        onSuccess={onSuccess}
        onFail={onFail}
      />
      <ActivationsAdditionDialog
        open={addSelectedActivationsOpen}
        onClose={() => toggleAddSelectedActivationsOpen(false)}
      />
      <ActivationsDeleteDialog
        open={deleteSelectedActivationsOpen}
        onClose={() => toggleDeleteSelectedActivationsOpen(false)}
      />
      <Version>{version}</Version>
      <SimpleSnackbar snackbarState={snackbarState.state} setSnackbarState={snackbarState.setState} />
    </>
  );
}

export default Debugger;
