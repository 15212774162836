import {V2PeopleTypes} from '@bitkey-service/v2_core-types/lib/common/v2_commonTypes';
import {
  CollectionOrgReservationSettings,
  StoreTypesOrgReservationSettings,
} from '@bitkey-service/v2_core-types/lib/store/organizations/reservation-settings/storeTypesOrgReservationSettings';
import {
  MailTemplateType,
  V2StoreTypesWHOrgReservationSetting,
} from '@bitkey-service/v2_core-types/lib/store/organizations/workhub-reservation-settings/v2_storeTypesWHOrgReservationSetting';
import ArrayUtil from '../../../utils/arrayUtil';
import {Firestore} from '../firebase-firestore';
import {limit, query, where} from 'firebase/firestore';

/**
 * NOTE: 2023年3月現在、"予約できるthings"は"備品"のみであるという前提
 */
type WorkhubSettingsType = V2StoreTypesWHOrgReservationSetting['type'];

const ref = (organizationId: string) =>
  Firestore.getInstance().getCollectionRef(CollectionOrgReservationSettings(organizationId));

export class FirestoreOrgReservationSettings {
  public static ref = (organizationId: string) => ref(organizationId);
  public static getById = (organizationId: string, id: string) =>
    Firestore.getInstance().getById(ref(organizationId), id);
  public static getAll = (organizationId: string) => Firestore.getInstance().getByQuery(ref(organizationId));
  public static getReservableSpaces = (organizationId: string): Promise<StoreTypesOrgReservationSettings[]> =>
    Firestore.getInstance().getByQuery(
      query(ref(organizationId), where('reservable', '==', true), where('workhubSettings.type', '==', 'spaces'))
    );
  public static getReservableThings = (organizationId: string) =>
    Firestore.getInstance().getByQuery(
      query(ref(organizationId), where('reservable', '==', true), where('workhubSettings.type', '==', 'things'))
    );

  public static getByTargetTypeAndTargetId = (
    organizationId: string,
    type: string,
    targetId: string
  ): Promise<StoreTypesOrgReservationSettings[]> =>
    Firestore.getInstance().getByQuery(
      query(
        ref(organizationId),
        where('workhubSettings.type', '==', type),
        where('workhubSettings.targetId', '==', targetId)
      )
    );
  public static getBySpaceId = (organizationId: string, spaceId: string): Promise<StoreTypesOrgReservationSettings[]> =>
    FirestoreOrgReservationSettings.getByTargetTypeAndTargetId(organizationId, 'spaces', spaceId);
  public static getByThingId = (organizationId: string, thingId: string): Promise<StoreTypesOrgReservationSettings[]> =>
    FirestoreOrgReservationSettings.getByTargetTypeAndTargetId(organizationId, 'things', thingId);
  public static getByIds = (organizationId: string, ids: string[]): Promise<StoreTypesOrgReservationSettings[]> =>
    Firestore.getInstance().getByIds(ref(organizationId), ids);

  public static getByMailTemplateId = async (
    organizationId: string,
    templateId: string
  ): Promise<StoreTypesOrgReservationSettings[]> => {
    //sendableMailSettingsの構造的にfirestoreのクエリで絞れないのでjsでフィルタする
    const allReservationSettings = await FirestoreOrgReservationSettings.getAll(organizationId);

    return allReservationSettings.filter(setting => {
      if (setting.workhubSettings?.mailTemplateIds?.includes(templateId)) {
        return true;
      }

      const sendableMailSettings = setting.workhubSettings?.sendableMailSettings;
      if (!sendableMailSettings) {
        return false;
      }

      const mailTemplateIds = ArrayUtil.removeEmpty(
        Object.keys(sendableMailSettings).flatMap(peopleType => {
          const mailTypeSettings = sendableMailSettings[peopleType as V2PeopleTypes]?.mailTypeSettings;
          if (!mailTypeSettings) {
            return [];
          }

          return Object.keys(mailTypeSettings).map(
            mailType => mailTypeSettings[mailType as MailTemplateType]?.templateId
          );
        })
      );

      return mailTemplateIds.includes(templateId);
    });
  };

  public static getByTargetPatternId = async (organizationId: string, targetPatternId: string) => {
    const reservationSettings = await Promise.all(
      Object.values(V2PeopleTypes).map(peopleType =>
        Firestore.getInstance().getByQuery(
          query(
            ref(organizationId),
            where(`workhubSettings.availableACPatterns.${peopleType}`, 'array-contains', targetPatternId)
          )
        )
      )
    );
    return reservationSettings.flat();
  };

  public static existsByWorkhubSettingsType = async (
    organizationId: string,
    type: WorkhubSettingsType
  ): Promise<boolean> => {
    const data: StoreTypesOrgReservationSettings[] = await Firestore.getInstance().getByQuery(
      query(ref(organizationId), where('workhubSettings.type', '==', type), limit(1))
    );
    return data && data.length > 0;
  };
}
