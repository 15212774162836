import {createSlice} from '@reduxjs/toolkit';
import {OrganizationState} from '../state-types/organizationStateType';

const initialState: OrganizationState = {
  id: '',
  code: '',
  nameJp: '',
  nameEn: '',
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<OrganizationState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

export default organizationSlice;
