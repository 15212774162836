import {configureStore} from '@reduxjs/toolkit';
import {rootReducer} from './rootReducer';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {RootState} from './rootStateType';
import {experimentalFeatureMiddleware} from '../slices/experimentalFeaturesSlice';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(experimentalFeatureMiddleware),
});

export const setupStore = () => store;
export type AppDispatch = typeof store.dispatch;

/**
 * 通常のuseDispatchだとcreateAsyncThunkで作成したthunkをdispatchした際にpromiseを返さない。
 * 呼び出し元で非同期処理のaction(pending,fulfilled,rejected)を処理したいときに利用する。
 * もちろん通常のuseDispatchとしても利用できる。
 */
export const useAsyncDispatch = () => useDispatch<AppDispatch>();

/**
 * 型定義を行ったuseSelector。呼び出し元で型の上書きをしないで良いようにここで定義
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
