export class ValidationUtil {
  /**
   * @param email メールアドレス
   * @returns invalid: false, valid: true
   */
  public static isValidEmail = (email: string) => {
    return /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
  };
  /**
   * @param tel 電話番号
   * @returns invalid: false, valid: true
   */
  public static isValidTelPhone = (tel: string) => {
    //桁数指定にハイフンも含まれるため、[0120-1111-] <- これでも通ってしまう。
    //そのため、ハイフンの有無で桁数の分岐を追加
    const hyphenLength = tel.match(/-/g)?.length ?? 0;

    if (hyphenLength) {
      const telReg = /^[0-9\\-]{12,15}$/;
      return hyphenLength === 2 ? telReg.test(tel) : false;
    } else {
      const telReg = /^[0-9\\-]{10,13}$/;
      return telReg.test(tel);
    }
  };

  public static isValidNameEnglish = (name: string) => {
    return /^[0-9a-zA-Z]*$/.test(name);
  };
  public static isValidPostCode = (postCode: string) => {
    return /^\d{7}$/.test(postCode);
  };

  public static isValidEnglishSymbolName = (name: string) => {
    return /^[a-zA-Z\-!@#$%^&*()_+={}|:;"'<,>.?]*$/.test(name);
  };
  public static isValidEnglishSymbolNameWithSpace = (name: string) => {
    return /^[a-zA-Z0-9\-!@#$%^&*()_+={}|:;"'<,>.?\s]*$/.test(name);
  };

  public static isValidNameJpKana = (name: string) => {
    return /^([ァ-ヴ]|ー)*$/.test(name);
  };

  public static isValidAccountHolder = (name: string) => {
    return /^[ァ-ヶー）（Ａ-Ｚ０-９／‐，「」．]+$/u.test(name);
  };

  /**
   * iDmのValidation。大小英字が混在する場合はNGとする。
   * @param idm NFCカードIDm
   * @returns invalid: false, valid: true
   */

  public static isValidNfcIdm = (idm: string) => {
    const idmReg1 = /^(?:[0-9a-f][0-9a-f])*$/;
    const idmReg2 = /^(?:[0-9A-F][0-9A-F])*$/;
    return idmReg1.test(idm) || idmReg2.test(idm);
  };

  /**
   * コードのフォーマットチェック。
   * 空文字はtrue
   * @param code コード
   */
  public static isValidCode = (code: string) => {
    return /^[0-9a-zA-Z-_()[\].]*$/.test(code);
  };

  /**
   * 時刻のフォーマットチェック。
   * 空文字はtrue
   * @param time HH:mm形式の時刻
   */
  public static isValidTimeFormat = (time: string) => {
    if (!time) {
      return true;
    }
    const timeReg = /^((0?|1)[0-9]|2[0-3]):[0-5][0-9]$/; //0:00-23:59
    return timeReg.test(time) || time === '24:00';
  };

  /**
   * 時刻のフォーマットチェック。
   * 空文字はtrue
   * @param time HH:mm形式の時刻
   */
  public static isValidTimeFormatHHmm = (time: string) => {
    if (!time) {
      return true;
    }
    const timeReg = /^((0|1)[0-9]|2[0-3]):[0-5][0-9]$/; //00:00-23:59
    return timeReg.test(time) || time === '24:00';
  };

  public static isValidAlphaNumeric = (value: string) => {
    return /^[0-9a-zA-Z]*$/.test(value);
  };

  public static isValidAlphabet = (value: string) => {
    return /^[a-zA-Z]*$/.test(value);
  };
  /**
   * パスワードの長さが8文字以上かチェック
   * @param password パスワード
   */
  public static isValidPassword = (password: string) => {
    return /^[a-zA-Z\d !"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]{8,}$/.test(password) && !this.containsWhiteSpace(password);
  };

  /**
   * URLのバリデーション
   * @param url url
   */
  public static isValidUrl = (url: string) => {
    return /^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/.test(url);
  };

  public static containsWhiteSpace = (value: string) => {
    return /\s/.test(value);
  };

  public static isExistValue = (existValues: string[], inputValue: string) => {
    return existValues.includes(inputValue);
  };

  public static isValidNumber = (value: string) => {
    return /^[0-9]+$/.test(value);
  };
}
