import type {
  ApiTypesCreateDropInPlansRes,
  ApiTypesPatchDropInPlansReq,
  ApiTypesCreateDropInPlansReq,
  ApiTypesGetDropInPlansAndPricePlansRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/drop-in-plans/apiTypesDropInPlans';
import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../../common/firebase/functions/firebase-functions';
import {ApiCoreOrg} from '../apiCoreOrg';
import {StoreTypesOrgDropInPlans} from '@bitkey-service/v2_core-types/lib/store/organizations/drop-in-plans/storeTypesOrgDropInPlans';

const basePath = '/drop-in-plans';

export class ApiDropInPlans {
  static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.get<Response>(FirebaseFunctions.makePath(basePath, path), config);
  static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.delete<Response>(FirebaseFunctions.makePath(basePath, path), config);

  /** ドロップインプランを全て取得する */
  public static getDropInPlansAll = () => {
    return ApiDropInPlans.get<StoreTypesOrgDropInPlans[]>('/');
  };

  /** idを使ってドロップインプランとそれに紐づく料金プランを取得する */
  public static getDropInPlanAndPricePlanById = (id: string) => {
    return ApiDropInPlans.get<ApiTypesGetDropInPlansAndPricePlansRes>(`/${id}/price-plans`);
  };

  /** codeの重複チェック */
  public static checkDuplicationCode = (arg: {organizationId: string; code: string}) => {
    return ApiDropInPlans.post<boolean, {organizationId: string; code: string}>(`/check-duplicate-code`, arg);
  };

  /** ドロップインプランの新規作成 */
  public static createDropInPlan = (body: ApiTypesCreateDropInPlansReq) =>
    ApiDropInPlans.post<ApiTypesCreateDropInPlansRes, ApiTypesCreateDropInPlansReq>('/', body);

  /** ドロップインプランの更新 */
  public static patchDropInPlan = (body: ApiTypesPatchDropInPlansReq) =>
    ApiDropInPlans.patch<unknown, ApiTypesPatchDropInPlansReq>('/', body);

  /** ドロップインプランの削除 */
  public static deletedDropInPlan = (id: string) => ApiDropInPlans.delete(`/${id}`);

  /** ドロップインプランの一括削除 */
  public static bukDeletedDropInPlan = async (ids: string[]) => {
    const params = new URLSearchParams();
    ids.forEach(v => params.append('ids', v));
    await ApiDropInPlans.delete(`/bulk/delete?${params.toString()}`);
  };
}
